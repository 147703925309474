<template>

  <div>
    <table v-if="cerdata !=null" width="1180" border="0px solid black" cellspacing="0"  style="margin:0 auto">
      <tr style="text-align: center;width: 100%">
        <div style="text-align: center;width: 100%;">
          <h2 style="font-weight:bold">电动自行车产品合格证</h2>
          <h4>产品合格证编号：{{cerdata.certificateno}}</h4>
          <!--            <h3>第一部分车辆总体信息</h3>-->
        </div>
        <!--            <tr>-->
        <!--              <td colspan="4" align="left" style="padding-top:10px;padding-bottom:10px">产品合格证编号：{{cerdata.certificateno}}</td>-->
        <!--            </tr>-->
      </tr>
      <tr>
        <td align="center" valign="bottom">
          <table  width="590" border="1px solid black" cellspacing="0" style="font-size:12px;" >
            <tr>
              <td colspan="4" align="center" style="padding-top:20px;padding-bottom:20px"><h2>第一部分 车辆总体信息</h2></td>
            </tr>
            <tr >
              <td width="22%" style="padding-top:20px;padding-bottom:20px">0.0 整车编码：</td>
              <td colspan="3">{{cerdata.vehicleNo}}</td>
            </tr>
            <tr >
              <td width="22%" style="padding-top:20px;padding-bottom:20px">0.1 车辆生产者(制造商)：</td>
              <td colspan="3">{{cerdata.manufacturer}}</td>
            </tr>
            <tr>
              <td width="22%" style="padding-top:20px;padding-bottom:20px">0.2 生产企业名称：</td>
              <td colspan="3">{{cerdata.producer}}</td>
            </tr>
            <tr>
              <td width="22%" style="padding-top:20px;padding-bottom:20px">0.3 生产企业地址</td>
              <td colspan="3">{{cerdata.produceraddr}}</td>
            </tr>
            <tr>
              <td width="22%" style="padding-top:20px;padding-bottom:20px">0.4 车辆中文商标：</td>
              <td width="22%" >{{(cerdata.cnbrand =='null' ||cerdata.cnbrand ==null)?'':cerdata.cnbrand}}</td>
              <td width="22%" style="padding-top:20px;padding-bottom:20px">0.5 车辆英文商标：</td>
              <td width="22%">{{(cerdata.enbrand =='null' ||cerdata.enbrand ==null)?'':cerdata.enbrand}}</td>
            </tr>
            <tr>
              <td width="22%" style="padding-top:10px;padding-bottom:10px">0.6 产品型号：</td>
              <td width="22%">{{cerdata.modelno}}</td>
              <td width="22%" style="padding-top:20px;padding-bottom:20px">0.7 驱动方式：</td>
              <td width="22%">{{cerdata.drivetype}}</td>
            </tr>
            <tr>
              <td width="22%" style="padding-top:20px;padding-bottom:20px">0.8 长×宽×高：<br/>（mm×mm×mm）</td>
              <td width="22%">{{cerdata.bodysize}}</td>
              <td width="22%" style="padding-top:20px;padding-bottom:20px">0.9 前后轮中心距：<br/>（mm）</td>
              <td width="22%">{{cerdata.wheeltrack}}</td>
            </tr>
            <tr>
              <td style="padding-top:10px;padding-bottom:10px" colspan="2">0.10 装配完整的电动自行车的整车质量（kg）：</td>
              <td colspan="2">{{cerdata.totalweight}}</td>
            </tr>
            <tr>
              <td colspan="4">
                <table width="590" cellspacing="0" style="font-size:12px; " >
                  <tr >
                    <td width="45%" colspan="2" style="border-bottom: 1px solid black;border-right: 1px solid black;padding-top:20px;padding-bottom:20px">0.11 最高设计车速（km/h）：</td>
                    <td width="55%" colspan="2"style="border-bottom: 1px solid black;border-left: 1px solid black;">{{cerdata.maxspeed}}</td>
                  </tr>
                  <tr>
                    <td width="45%" colspan="2" style="padding-top:20px;padding-bottom:20px;border-top: 1px solid black;border-bottom: 1px solid black;border-right: 1px solid black;">0.12 铭牌固定位置：</td>
                    <td width="55%" colspan="2" style="border-top: 1px solid black;border-bottom: 1px solid black;border-left: 1px solid black;">{{cerdata.cardposition}}</td>
                  </tr>
                  <tr>
                    <td width="45%" colspan="2" style="padding-top:20px;padding-bottom:20px;border-top: 1px solid black;border-bottom: 1px solid black;border-right: 1px solid black;">0.13 车架上车辆编码的位置：</td>
                    <td width="55%" colspan="2" style="border-top: 1px solid black;border-bottom: 1px solid black;border-left: 1px solid black;">{{cerdata.codeposition}}</td>
                  </tr>
                  <tr>
                    <td width="45%" colspan="2" style="padding-top:20px;padding-bottom:20px;border-top: 1px solid black;border-bottom: 1px solid black;border-right: 1px solid black;">0.14 电动机编码：</td>
                    <td width="55%" colspan="2" style="border-top: 1px solid black;border-bottom: 1px solid black;border-left: 1px solid black;">{{cerdata.motorNo}}</td>
                  </tr>
                  <tr>
                    <td width="45%" colspan="2" style="padding-top:20px;padding-bottom:20px;border-top: 1px solid black;border-bottom: 1px solid black;border-right: 1px solid black;">0.15 续行里程（km）：</td>
                    <td width="55%" colspan="2" style="border-top: 1px solid black;border-bottom: 1px solid black;border-left: 1px solid black;">{{cerdata.driverange}}</td>
                  </tr>
                  <tr>
                    <td width="45%" colspan="2" style="padding-top:20px;padding-bottom:20px;border-top: 1px solid black;border-bottom: 1px solid black;border-right: 1px solid black;">0.16 百公里电耗（kW.h/100km）：</td>
                    <td width="55%" colspan="2" style="border-top: 1px solid black;border-bottom: 1px solid black;border-left: 1px solid black;">{{cerdata.powerconsumption}}</td>
                  </tr>
                  <tr>
                    <td width="45%" colspan="2" style="padding-top:20px;padding-bottom:20px;border-top: 1px solid black;border-bottom: 1px solid black;border-right: 1px solid black;">0.17.1 CCC证书编号：</td>
                    <td width="55%" colspan="2" style="border-top: 1px solid black;border-bottom: 1px solid black;border-left: 1px solid black;">{{(cerdata.cccno =='null' ||cerdata.cccno ==null)?'':cerdata.cccno}}</td>
                  </tr>
                  <tr>
                    <td width="45%" colspan="2" style="padding-top:20px;padding-bottom:20px;border-top: 1px solid black;border-bottom: 1px solid black;border-right: 1px solid black;">0.17.2 CCC证书版本号：</td>
                    <td width="55%" colspan="2" style="border-top: 1px solid black;border-bottom: 1px solid black;border-left: 1px solid black;">{{ (cerdata.cccver =='null' ||cerdata.cccver ==null)?'':cerdata.cccver}}</td>
                  </tr>
                  <tr>
                    <td width="45%" colspan="2" style="padding-top:20px;padding-bottom:20px;border-top: 1px solid black;border-bottom: 1px solid black;border-right: 1px solid black;">0.17.3 CCC证书发证日期：</td>
                    <td width="55%" colspan="2" style="border-top: 1px solid black;border-bottom: 1px solid black;border-left: 1px solid black;">{{cerdata.cccdate==null?'':cerdata.cccdate.replace(/\s[\x00-\xff]*/g,'')}}</td>
                  </tr>
                  <tr v-if="this.cerdata.cccindex==2">
                    <td width="45%" colspan="2" style="padding-top:20px;padding-bottom:20px;border-top: 1px solid black;border-bottom: 1px solid black;border-right: 1px solid black;">0.18 车身颜色：</td>
                    <td width="55%" colspan="2" style="border-top: 1px solid black;border-bottom: 1px solid black;border-left: 1px solid black;">{{cerdata.vehicleColor==null?'':cerdata.vehicleColor}}</td>
                  </tr>
                  <tr v-if="this.cerdata.cccindex==2">
                    <td width="45%" colspan="2" style="padding-top:20px;padding-bottom:20px;border-top: 1px solid black;border-right: 1px solid black;">0.19 车辆制造日期：</td>
                    <td width="55%" colspan="2" style="border-top: 1px solid black;border-left: 1px solid black;">{{cerdata.madeDate==null?'':cerdata.madeDate.replace(/\s[\x00-\xff]*/g,'')}}</td>
                  </tr>
                </table>
              </td>
            </tr>

          </table>
        </td>
        <td align="center" valign="bottom" style="padding-left:20px">
          <table  width="590" border="1px solid black" cellspacing="0" style="font-size:11px;">
            <!--            <tr>-->
            <!--              <td colspan="4" align="left" style="padding-top:10px;padding-bottom:10px">产品合格证编号：{{cerdata.certificateno}}</td>-->
            <!--            </tr>-->
            <tr>
              <td colspan="4" align="center" style="padding-top:10px;padding-bottom:10px"><h2>第二部分 产品合格证参数</h2></td>
            </tr>
            <tr style="height: 380px;">
              <td colspan="4" align="center">
                <img ref="shimgref" width="400px" height="350px"  :src="bikeimgurl" />
              </td>
            </tr>
            <tr>
              <td width="22%" style="padding-top:15px;padding-bottom:15px">1.1 电动机生产企业</td>
              <td width="31%" colspan="3">{{cerdata.motorproducer}}</td>
            </tr>
            <tr>
              <td width="22%" style="padding-top:15px;padding-bottom:15px">1.2 电动机型号</td>
              <td width="31%" colspan="3">{{cerdata.motormodel}}</td>
            </tr>
            <tr>
              <td width="22%" style="padding-top:15px;padding-bottom:15px">1.3 电动机型式</td>
              <td width="31%">{{cerdata.motortype}}</td>
              <td width="22%" style="padding-top:15px;padding-bottom:15px">1.4 额定转速(r/min)</td>
              <td width="31%">{{cerdata.rotationspeed}}</td>
            </tr>
            <tr>
              <td width="22%" style="padding-top:15px;padding-bottom:15px">1.5 额定连续输出功率(W)</td>
              <td width="31%">{{cerdata.outputpower}}</td>
              <td width="22%" style="padding-top:15px;padding-bottom:15px">1.6 额定电压(V)</td>
              <td width="31%">{{cerdata.voltage}}</td>
            </tr>
            <tr>
              <td width="22%" style="padding-top:15px;padding-bottom:15px">2.1 控制器生产企业</td>
              <td width="31%" colspan="3">{{cerdata.controllerproducer}}</td>
            </tr>
            <tr>
              <td width="22%" style="padding-top:15px;padding-bottom:15px">2.2 控制器型号</td>
              <td width="31%" colspan="3">{{cerdata.controllerno}}</td>
            </tr>
            <tr>
              <td width="22%" style="padding-top:15px;padding-bottom:15px">2.3 欠压保护值（V）</td>
              <td width="31%">{{cerdata.undervoltage}}</td>
              <td width="22%" style="padding-top:15px;padding-bottom:15px">2.4 过流保护值(A)</td>
              <td width="31%">{{cerdata.overcurrent}}</td>
            </tr>
            <tr>
              <td width="22%" style="padding-top:15px;padding-bottom:15px">3.1 蓄电池生产企业</td>
              <td width="31%" colspan="3">{{cerdata.cellproducer}}</td>
            </tr>
            <tr>
              <td width="22%" style="padding-top:15px;padding-bottom:15px">3.2 蓄电池类型</td>
              <td width="31%">{{cerdata.celltype}}</td>
              <td width="22%" style="padding-top:15px;padding-bottom:15px">3.3 蓄电池容量(Ah)</td>
              <td width="31%">{{cerdata.cellvolume}}</td>
            </tr>
            <tr>
              <td width="22%" style="padding-top:15px;padding-bottom:15px">3.4 蓄电池型号</td>
              <td width="31%">{{cerdata.cellno}}</td>
              <td width="22%" style="padding-top:15px;padding-bottom:15px">3.5 锂离子蓄电池编码<br/>（前十位）</td>
              <td width="31%">{{cerdata.cellNum}}</td>
            </tr>
            <tr>
              <td width="22%" style="padding-top:15px;padding-bottom:15px">4.1 充电器生产企业</td>
              <td width="31%" colspan="3">{{cerdata.chargerProducer}}</td>
            </tr>
            <tr>
              <td width="22%" style="padding-top:15px;padding-bottom:15px">4.2 充电器型号</td>
              <td width="31%">{{cerdata.chargerspecs}}</td>
              <td width="22%" style="padding-top:15px;padding-bottom:15px">4.3 充电方式</td>
              <td width="31%">{{cerdata.chargerMethod}}</td>
            </tr>
            <tr>
              <td width="22%" style="padding-top:15px;padding-bottom:15px">4.4 充电器编码</td>
              <td width="31%">{{cerdata.chargerNum}}</td>
              <td width="22%" rowspan="3">二维码</td>
              <td width="31%" rowspan="3">
                <!--                <vue-qr v-if="showccc" :text="cccUrl" :correctLevel=3  :size="100" :margin="1" :callback="test1" qid="cid1" style="margin-left:10px;float:left;width:95px;height:95px"></vue-qr>-->
                <!--                <vue-qr v-if="showself" :text="selfUrl" :correctLevel=2 :callback="test2" :size="100" :margin="1" qid="cid2" style="margin-right:10px;float:right;width:95px;height:95px"></vue-qr>-->
              </td>
            </tr>
            <tr>
              <td width="22%" style="padding-top:15px;padding-bottom:15px">5.1 前轮轮胎规格</td>
              <td width="31%">{{cerdata.frontwheel}}</td>
            </tr>
            <tr>
              <td width="22%" style="padding-top:15px;padding-bottom:15px">5.2 后轮轮胎规格</td>
              <td width="31%">{{cerdata.backwheel}}</td>
            </tr>
            <tr >
              <td width="22%" style="padding-top:15px;padding-bottom:15px">6 备注</td>
              <td width="31%">{{(cerdata.note =='null' ||cerdata.note ==null)?'':cerdata.note}}</td>
            </tr>
            <!--          <tr>-->
            <!--            <td colspan="4" >本产品经过检验，符合{{cerdata.standardname2}}的要求，特此证明。</td>-->
            <!--          </tr>-->
          </table>
          <!--                 <img border="0" v-if="cerdata.printseal=='true'" width="150px" height="150px" transcolor="#FFFFFF" :src="'/api/files/get/seal?id='+cerdata.sealaddrid" style="z-index: -1; position: absolute; left:650px; top:470px;" />-->
        </td>
      </tr>
    </table>
    <div style="text-align: center">
            <h6>本产品经过检验，符合{{cerdata.standardname}}的要求，特此证明。(上图为外形简图)</h6>
<!--      <span>本产品经过检验，符合</span>-->
<!--      <span v-if="cerdata.standardname2.split(',').includes('GB17761-2018《电动自行车安全技术规范》')">-->
<!--                    <img ref="shimgref" width="10px" height="10px"  src="./gou.jpg" /> GB17761-2018《电动自行车安全技术规范》-->
<!--                  </span>-->
<!--      <span v-else>-->
<!--                    <img ref="shimgref" width="10px" height="10px"  src="./kuai.jpg" /> GB17761-2018《电动自行车安全技术规范》-->
<!--                  </span>-->
<!--      <span v-if="cerdata.standardname2.split(',').includes('GB42295-2022《电动自行车电气安全要求》')">-->
<!--                      <img ref="shimgref" width="10px" height="10px"  src="./gou.jpg" /> GB42295-2022《电动自行车电气安全要求》-->
<!--                    </span>-->
<!--      <span v-else>-->
<!--                      <img ref="shimgref" width="10px" height="10px"  src="./kuai.jpg" /> GB42295-2022《电动自行车电气安全要求》-->
<!--                    </span>-->
<!--      <span v-if="cerdata.standardname2.split(',').includes('GB42296-2022《电动自行车用充电器安全技术要求》')">-->
<!--                      <img ref="shimgref" width="10px" height="10px"  src="./gou.jpg" /> GB42296-2022《电动自行车用充电器安全技术要求》-->
<!--                    </span>-->
<!--      <span v-else>-->
<!--                      <img ref="shimgref" width="10px" height="10px"  src="./kuai.jpg" /> GB42296-2022《电动自行车用充电器安全技术要求》-->
<!--                  </span>-->
<!--      <span>的要求,特此证明。(上图为外形简图)</span>-->
    </div>
  </div>

</template>

<script>
export default {
  name: 'cptableV2to1hao',
  props: {
    cerdata: {
      type: Object,      //类型
      default: {}     //默认值
    },
    bikeimgurl:{
      type:String,
      default:''

    },


  },

  mounted(){
    // var checkboxes = document.querySelectorAll('input[type="checkbox"]');
    // console.log("checkboxes",checkboxes)
    // checkboxes.forEach(item=>{
    //   if (this.cerdata.standardname2.split(",").includes(item.value)) {
    //     item.checked = true;
    //   }
    // })
  },

}
</script>

<style scoped lang="scss">


</style>
