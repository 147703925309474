import { render, staticRenderFns } from "./CertificatePrint2to1hao.vue?vue&type=template&id=966c8d68&scoped=true"
import script from "./CertificatePrint2to1hao.vue?vue&type=script&lang=js"
export * from "./CertificatePrint2to1hao.vue?vue&type=script&lang=js"
import style0 from "./CertificatePrint2to1hao.vue?vue&type=style&index=0&id=966c8d68&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "966c8d68",
  null
  
)

export default component.exports