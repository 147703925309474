<!-- 生产车间合格证打印功能-->
<template>
  <div class="downlinediv">
    <Modal v-model="showcerdiv" title="合格证书" width="90%" margin-top:10px @on-visible-change="onshowcerdiv" @on-ok="confirmpreviewtest">
      <div id="cerdiv" style="width:1160px;margin: 0 auto;">
        <!-- <cerview v-bind:cerprintdata="printer.cerprintdata" /> -->
        <!--21版-->
        <span v-if="!yiqi23">
          <cerview v-if="printer.printv2=='不试用'" ref="cerviewref" @qrcodesfinished="onprintviewqrcodefinished" style="width: 100%;"/>
          <cerviewv2 v-else ref="cerviewref" @qrcodesfinished="onprintviewqrcodefinished" style="width: 100%;"/>
        </span>
<!--        <span v-if="!yihao">-->
<!--          <certificate-print3to1hao v-if="printer.printv2=='不试用'" ref="cerviewref" @qrcodesfinished="onprintviewqrcodefinished" style="width: 100%;" />-->
<!--          <CertificatePrintV23to1hao v-else ref="cerviewref" @qrcodesfinished="onprintviewqrcodefinished" style="width: 100%;"/>-->
<!--        </span>-->

        <span v-else-if="cccindex==3">
          <span v-if="!yihao">
            <certificate-print3to23 v-if="printer.printv2=='不试用'" ref="cerviewref" @qrcodesfinished="onprintviewqrcodefinished" style="width: 100%;"/>
            <cerviewV23 v-else ref="cerviewref" @qrcodesfinished="onprintviewqrcodefinished" style="width: 100%;"/>
          </span>
          <span v-else>
            <certificate-print3to1hao v-if="printer.printv2=='不试用'" ref="cerviewref" @qrcodesfinished="onprintviewqrcodefinished" style="width: 100%;" />
            <CertificatePrintVto1hao v-else ref="cerviewref" @qrcodesfinished="onprintviewqrcodefinished" style="width: 100%;"/>
          </span>

        </span>
        <span v-else-if="cccindex==4">
           <span v-if="!yihao">
              <certificate-print4to23 v-if="printer.printv2=='不试用'" ref="cerviewref" @qrcodesfinished="onprintviewqrcodefinished" style="width: 100%;"/>
              <cerviewV23 v-else ref="cerviewref" @qrcodesfinished="onprintviewqrcodefinished" style="width: 100%;"/>
           </span>
            <span v-else>
              <certificate-print4to1hao v-if="printer.printv2=='不试用'" ref="cerviewref" @qrcodesfinished="onprintviewqrcodefinished" style="width: 100%;"></certificate-print4to1hao>
              <CertificatePrintVto1hao v-else ref="cerviewref" @qrcodesfinished="onprintviewqrcodefinished" style="width: 100%;"/>
            </span>

        </span>
        <span v-else-if="cccindex==2">
          <span v-if="!yihao">
            <certificate-print2to23 v-if="printer.printv2=='不试用'" ref="cerviewref" @qrcodesfinished="onprintviewqrcodefinished" style="width: 100%;"/>
            <cerviewV23 v-else ref="cerviewref" @qrcodesfinished="onprintviewqrcodefinished" style="width: 100%;"/>
          </span>
          <span v-else>
            <certificate-print2to1hao v-if="printer.printv2=='不试用'" ref="cerviewref" @qrcodesfinished="onprintviewqrcodefinished" style="width: 100%;"></certificate-print2to1hao>
            <CertificatePrintVto1hao v-else ref="cerviewref" @qrcodesfinished="onprintviewqrcodefinished" style="width: 100%;"/>
          </span>
        </span>
        <span v-else-if="cccindex==5">
          <certificate-print5to23 v-if="printer.printv2=='不试用'" ref="cerviewref" @qrcodesfinished="onprintviewqrcodefinished" style="width: 100%;" />
          <cerviewV23 v-else ref="cerviewref" @qrcodesfinished="onprintviewqrcodefinished" style="width: 100%;"/>
        </span>
      </div>
    </Modal>
    <Modal v-model="showcodediv" title="箱贴预览" width="90%" margin-top:10px @on-ok="oncodepreview">
      <div id="codediv"  style="margin:0 auto;float:top;margin-top:1px">
          <codeview v-bind:vehicledata="printer.cerprintdata"/>
      </div>
    </Modal>
    <Modal v-model="showboxsizeedit" title="箱贴纸张尺寸设置" :mask-closable="false" @on-ok="oncodeprintersizeconfig">
        <Form ref="boxsizesetting" :model="printer.codeprinterformInline" label-position="right" :label-width="120" >
            <FormItem prop="width" label="宽度(mm):">
              <i-input type='number'  v-model="printer.codeprinterformInline.width" />
            </FormItem>
            <FormItem prop="height" label="高度(mm):">
              <i-input type='number'  v-model="printer.codeprinterformInline.height" />
            </FormItem>
        </Form>
    </Modal>
    <Row style="width:100%;margin:auto;margin-top:0px;margin-bottom:15px;">
      <i-col span="16">
        <Card style="height:100%">
            <p slot="title" align="center">
                <Icon type="ios-print"></Icon>
                <strong>电动车合格证打印系统</strong>
            </p>
            <Form ref="CertificatePrintForm" :model="formInline" label-position="right" :label-width="120" :rules="ruleInline">
              <Row style="padding-top:5px;padding-bottom:10px">
                <i-col span="8">
                  <FormItem prop="cnmodelno" label="车型名称" >
                    <Select ref="cnmodelselect" v-model="formInline.cnmodelno" @on-change="onCnModelSelect">
                        <Option value='' :key=-1>请选择</Option>
                        <Option v-for="(item,index) in CnModelList" :value="item" :key="index">{{ item }}</Option>
                    </Select>
                  </FormItem>
                </i-col>
                <i-col span="11">
                  <FormItem prop="cerindex" label="车型配置别名:">
                    <Select ref="tempselect" v-model="formInline.cerindex" @on-change="onTmpSelect">
                        <Option :value=0 :key=0>请选择</Option>
                        <Option v-for="item in CertificateTemplateShowList" :value="item.cerdisplay.value" :key="item.cerdisplay.value">{{ item.cerdisplay.label+"("+item.cerdisplay.standard+")" }}</Option>
                    </Select>
                  </FormItem>
                </i-col>
                <i-col span="5" style="padding-left:5px;padding-top:5px">
                  <Button type="success" v-bind:disabled="syncdisabled" size="small" @click="refreshCertificatelist">{{synctext}}</Button>
                </i-col>
              </Row>
              <Row style="padding-top:5px;padding-bottom:10px">
                <i-col span="24">
                  <FormItem prop="certificateNo" label="产品合格证编码:" >
                      <i-input type='text' :disabled=true v-bind:value="formInline.certificateNo" />
                  </FormItem>
                </i-col>
              </Row>
              <Row style="padding-top:2px;padding-bottom:10px" type="flex" justify="start">
                <i-col span="14" >
                  <FormItem prop="vehicleNo" label="整车编码:">
                    <!--
                      <i-input ref="vehicleno" type="text" v-model="formInline.vehicleNo"  :autofocus="true" maxlength="20" show-word-limit clearable @on-change="onvehiclenochange"/>
                    -->
                    <input ref="vehicleno" type="text" v-model="formInline.vehicleNo" class="input-large" :autofocus="true" maxlength="20"  @on-change="onvehiclenochange"/>
                  </FormItem>
                </i-col>
                <i-col span="5">
                <FormItem prop="inputtype" :label-width="0">
                  <RadioGroup v-model="formInline.inputtype" size="small" vertical>
                      <Radio label="扫码输入" border></Radio>
                      <Radio label="手动输入" border></Radio>
                  </RadioGroup>
                </FormItem>
                </i-col>
                <i-col span="5">
                  <FormItem prop="autodelnum"  v-if="formInline.inputtype=='手动输入'" :label-width="0">
                    <p style="font-size:10px;text-align: left;">自删后</p>
                    <i-input type='number'  v-model="formInline.autodelnum" placeholder="自删后几位" >
                        <span slot="append">位</span>
                    </i-input>
                  </FormItem>
                </i-col>
              </Row>

              <Row style="padding-top:7px;padding-bottom:10px">
                <i-col span="14">
                  <div class="bigform">
                  <FormItem prop="motorNo" label="电动机编码:">
                    <!--
                      <i-input ref="motorno" type="text" v-model="formInline.motorNo" size="large" maxlength="50" show-word-limit clearable/>
                    -->
                      <input ref="motorno" type="text" v-model="formInline.motorNo" class="input-large" maxlength="50" show-word-limit autofocus/>
                  </FormItem>
                  </div>
                </i-col>
                <i-col span="5">
                <FormItem prop="motorinputtype" :label-width="0">
                  <RadioGroup v-model="formInline.motorinputtype" size="small" vertical="">
                      <Radio label="扫码输入" border></Radio>
                      <Radio label="手动输入" border></Radio>
                  </RadioGroup>
                </FormItem>
                </i-col>
                <i-col span="5" >
                  <FormItem prop="motorautodelnum"  v-if="formInline.motorinputtype=='手动输入'" :label-width="0">
                      <p style="font-size:10px;text-align: left;">自删后</p>
                      <i-input type='number'  v-model="formInline.motorautodelnum" placeholder="自删后几位" >
                          <span slot="append">位</span>
                      </i-input>
                  </FormItem>
                </i-col>
              </Row>
              <Row :gutter="20" style="padding-top:7px;padding-bottom:10px">
                <i-col span="24">
                  <FormItem prop="vehicleColor" label="车辆颜色:" align="left">
                    <!--
                      <i-input type="text" v-model="formInline.vehicleColor" size="large" style="width:45%" />
                      -->
                      <Select v-model="formInline.vehicleColor" style="width:45%;margin-left:5px" @on-change="onColorChange">
                        <Option v-for="(item,index) in vehicleColorList" :value="item" :key="index">{{ item }}</Option>
                      </Select>
                  </FormItem>
                </i-col>
              </Row>
              <Row :gutter="5" index="">
                <i-col span="10">
                  <FormItem prop="madeDate" label="制造日期:" align="left" style="padding-top:7px;padding-bottom:10px">
                      <DatePicker type="date" format="yyyy-MM-dd" placeholder="选择日期" v-model="formInline.madeDate" size="large" style="width: 200px" @on-change="formInline.madeDate=$event"></DatePicker>
                  </FormItem>
                </i-col>
                <i-col span="7" style="padding-top:5px">
                  <FormItem prop="boxcode" label="箱贴打印:" align="left" style="padding-top:0px;padding-bottom:10px">
                    <RadioGroup v-model="formInline.boxcode" size="small">
                        <Radio label="不打印" border></Radio>
                        <Radio label="打印" border></Radio>
                    </RadioGroup>
                    <i-input type="number" v-if="formInline.boxcode=='打印'" v-model="formInline.boxprintnum" style="width:25%;height:5%" placeholder="张数" />
                  </FormItem>
                </i-col>
                <i-col span="7" style="padding-top:5px">
                  <FormItem prop="cerdoprint" label="合格证:" align="left" style="padding-top:0px;padding-bottom:10px">
                    <RadioGroup v-model="formInline.cerdoprint" size="small">
                        <Radio label="不打印" border></Radio>
                        <Radio label="打印" border></Radio>
                    </RadioGroup>
                  </FormItem>
                </i-col>
              </Row>

              <FormItem prop="qrcode" label="二维码打印:" align="left" style="padding-top:7px;padding-bottom:5px">
                <RadioGroup v-model="formInline.qrcode"  size="small" @on-change='onqrchange'>
                    <Radio label="认证中心" border :disabled="formInline.standard=='企标'"></Radio>
<!--                    <Radio label="上海" border :disabled="formInline.standard=='企标'"></Radio>-->
                    <Radio label="厂家" border></Radio>
                    <Radio label="认证中心+厂家" border :disabled="formInline.standard=='企标'"></Radio>
<!--                    <Radio label="认证中心+上海" border :disabled="formInline.standard=='企标'"></Radio>-->
                </RadioGroup>
              </FormItem>
<!--              <FormItem prop="area" v-if="false" label="厂家合格证区域:" align="left" style="padding-top:0px;padding-bottom:5px">-->
<!--                <RadioGroup v-model="formInline.area" size="small">-->
<!--                    <Radio label="全国" border></Radio>-->
<!--                    <Radio label="上海" border></Radio>-->
<!--                </RadioGroup>-->
<!--              </FormItem>-->
              <FormItem>
                <!--
                  <Button type="primary" :disabled="printbtndisabled" @click="handleSubmit('CertificatePrintForm')" style="width:100%">打印</Button>
                -->
                  <div id="printbar" :disabled="printbtndisabled" @click="handleSubmit('CertificatePrintForm')">
                    <div style="font-size: 26px;color: white;">打印</div>
                    <p style="font-size: 14px;color: rgb(80, 9, 245);">点击我就为您打印合格证(及箱贴),安全更快捷,方便更高效,人性化系统创造者--牛商</p>
                  </div>
              </FormItem>
          </Form>
        </Card>
      </i-col>
      <i-col span="8" style="padding-left:15px;height: 100%;">
        <div style="display: inline-flex;margin: 0 auto;height: 40px;width:100%;background: white;text-align: center;cursor: pointer;" @click="printer.showprintercfg = !printer.showprintercfg">
          <p style="font-size: 20px;margin: 5px auto;text-align: center;">打印机配置<span style="margin-left: 18px;background: rgb(87, 116, 248);padding: 4px;color: white;border-radius: 4px;">{{printer.showprintercfg? "收起":"展开"}}</span></p>
        </div>
        <Card style="height:60%;margin-bottom:30px;" v-show="printer.showprintercfg==true">
          <Row  :gutter="5">
            <i-col span="6" align="right" style="padding-top:7px">
              <label  align="right" style="font-size:10px">新版本二维码:</label>
            </i-col>
            <i-col span="14" align="left">
              <RadioGroup v-model="printer.printv2" @on-change="onprintv2change" :disabled="!printer.startconfig">
                <Radio label="试用" :disabled="!printer.startconfig">
                </Radio>
                <Radio label="不试用" :disabled="!printer.startconfig">
                </Radio>
            </RadioGroup>
            </i-col>
            <i-col span="4" align="right" >

            </i-col>
          </Row>
            <Row  :gutter="5">
              <i-col span="6" align="right" style="padding-top:7px">
                <label  align="right" style="font-size:10px">合格证打印机:</label>
              </i-col>
              <i-col span="14" align="left">
                <Select v-model="printer.cerprinter" :disabled="!printer.startconfig"  @on-select="oncerprinterchange">
                  <Option v-for="(item,index) in printer.printerlist" :value="item.name" :key="index">{{ item.name }}</Option>
                </Select>
              </i-col>
              <i-col span="4" align="right" >
                <Button type="info" ghost style="font-size:10px" @click="oncerpreview">预览</Button>
              </i-col>
            </Row>
            <Row :gutter="1" index="" style="margin-top:5px">
              <i-col span="4" align="right" style="padding-top:7px">
                <p style="font-size:10px">打印水印:</p>
              </i-col>
              <i-col span="5" align="right" >
                <Select v-model="formInline.printwatermark" size="small" class="zskj-select-style">
                  <Option value='true' key="1">是</Option>
                  <Option value='false' key="2">否</Option>
                </Select>
              </i-col>
              <i-col span="6" align="right" style="padding-top:7px" >
                <p style="font-size:10px">打印检验章:</p>
              </i-col>
              <i-col span="5" align="right" >
                <Select v-model="formInline.printseal" size="small">
                  <Option value='true' key="1">是</Option>
                  <Option value='false' key="2">否</Option>
                </Select>
              </i-col>
              <i-col span="4" align="right" style="padding-left:3px">
                <Button type="info" ghost style="font-size:10px" @click="oncerpreview">预览</Button>
              </i-col>
            </Row>
            <Row :gutter="5" style="margin-top:5px" >
              <i-col span="6" align="right" style="padding-top:7px">
                <label  align="right" style="font-size:10px" >箱贴打印机:</label>
              </i-col>
              <i-col span="14" align="left">
                <Select v-model="printer.codeprinter" :disabled="!printer.startconfig"  @on-select="oncodeprinterchange">
                  <Option v-for="(item,index) in printer.printerlist" :value="item.name" :key="index">{{ item.name }}</Option>
                </Select>
              </i-col>
              <i-col span="4" align="right" >
                <Button type="info" ghost style="font-size:10px" @click="onboxpreview">预览</Button>
              </i-col>
            </Row>
            <Row  style="margin-top:5px">
              <i-col span="24" align="center" style="padding-top:7px">
                <Button type="warning" @click="printerOnclick" >{{printer.buttonText}}</Button>
              </i-col>
            </Row>
            <Button v-show="false" type="info" @click="testprint">打印机测试</Button>
        </Card>

        <Card :style="logstyle">
            <p slot="title">日志信息监控</p>
            <div style="height:100%;word-break:break-all;">
              <p align="left"  style="white-space: pre-line;margin-bottom:0px">{{logmsg.join("\r")}}</p>
            </div>
        </Card>
      </i-col>
    </Row>
<!--    <Modal v-model="showDupConfirm" title="重复提醒:整车编码已存在" @on-ok="updateVehicle">确定要覆盖并打印吗？</Modal>-->
    <Modal v-model="showDupConfirm"  >
      <div>
        <i-input type="password" v-model="adminpwd" placeholder="输入管理员密码"></i-input>
      </div>
      <div slot="footer">
        <Button type="primary" ghost size="large" @click="showDupConfirm=false">取消</Button>
        <Button type="primary" size="large" @click="checkpwd">校验</Button>
      </div>
    </Modal>
    </div>
</template>
<script>
import * as sysTool from '@/zsjs/systemTool.js'
import {getPrinters,printCer,printCerV2,printCerWithQrcode,printCerWithNum,printCode,setCerPrinterName,setCodePrinterName,cerPreview,cerPreviewV2,cerPreviewWithQrcode,cerPreviewWithNum,codePreview,addCerWatermark,printSetup,printDesign} from '@/zsjs/zskjprinter.js'
import CertificatePrint from '@/views/business/workshop/CertificatePrint.vue'
import CertificatePrintV2 from '@/views/business/workshop/CertificatePrintV2.vue'
import CertificatePrintV23 from '@/views/business/workshop/CertificatePrintV23.vue'
import CodePrint from '@/views/business/workshop/CodePrint.vue'
import UserInfoTitleBar from '@/views/common/UserInfoTitleBar.vue'
import CertificatePrint4to23 from './CertificatePrint4to23'
import CertificatePrint2to23 from './CertificatePrint2to23'
import CertificatePrint3to23 from './CertificatePrint3to23'
import CertificatePrint5to23 from './CertificatePrint5to23'
import CertificatePrint3to1hao from './CertificatePrint3to1hao'
import CertificatePrintVto1hao from './CertificatePrintVto1hao'
import CertificatePrint4to1hao from './CertificatePrint4to1hao'
import CertificatePrint2to1hao from './CertificatePrint2to1hao'
// 对Date的扩展，将 Date 转化为指定格式的String
// 月(M)、日(d)、小时(h)、分(m)、秒(s)、季度(q) 可以用 1-2 个占位符，
// 年(y)可以用 1-4 个占位符，毫秒(S)只能用 1 个占位符(是 1-3 位的数字)
// 例子：
// (new Date()).Format("yyyy-MM-dd hh:mm:ss.S") ==> 2006-07-02 08:09:04.423
// (new Date()).Format("yyyy-M-d h:m:s.S")      ==> 2006-7-2 8:9:4.18
Date.prototype.Format = function (fmt) {
    var o = {
        "M+": this.getMonth() + 1, //月份
        "d+": this.getDate(), //日
        "H+": this.getHours(), //小时
        "m+": this.getMinutes(), //分
        "s+": this.getSeconds(), //秒
        "q+": Math.floor((this.getMonth() + 3) / 3), //季度
        "S": this.getMilliseconds() //毫秒
    };
    if (/(y+)/.test(fmt)) fmt = fmt.replace(RegExp.$1, (this.getFullYear() + "").substr(4 - RegExp.$1.length));
    for (var k in o)
    if (new RegExp("(" + k + ")").test(fmt)) fmt = fmt.replace(RegExp.$1, (RegExp.$1.length == 1) ? (o[k]) : (("00" + o[k]).substr(("" + o[k]).length)));
    return fmt;
}
  function  makewatermark(print,watermarkid){
    var waterurl =window.location.protocol+"//"+window.location.host+'/api/files/get/watermark?id='+ watermarkid //

    var wdata =null
    if(print == 'true'){
      wdata = "<img border='0' src='"+waterurl+"' >"
    }
    console.log(wdata)
    return wdata
  }

export default {
  name: 'VehicleDownline',
  data () {
    const validatevin = (rule, value, callback) => {
      if(this.formInline.standard == '国标'){
        if (value == '') {
          return callback(new Error('整车编码不能为空'))
        } else if (value.length !=15) {
          return callback(new Error('整车编码长度不够15位'))
        } else {
          return callback()
        }
      }
      else{
        if (value == '') {
          return callback(new Error('整车编码不能为空'))
        } else {
          return callback()
        }
      }

    }
    const validatemotorno = (rule, value, callback) => {
      if(value == ''){
        return callback(new Error('电动机编码不能为空'))
      }

      if(this.formInline.standard == '国标'){
        if(RegExp(/W*V/).test(value) ==false){
          return callback(new Error('电动机编码需包含W和V'))
        }
      }
      return callback()
    }

    const validatenumber = (rule, value, callback) => {
        if (value == '') {
          return callback(new Error('不能为空'))
        }
        else if(value >10){
          return callback(new Error('最大10'))
        }
        else{
          return callback()
        }
    }

    const validatecommon =(rule,value,callback)=>{
      if(value ==0 || value ==-1){
        return callback(new Error('不能为空'))
      }
      else{
        return callback()
      }
    }

    return {
      userId:null,
      printer:{
        cerprinter:'',
        codeprinter:'',
        startconfig:false,
        buttonText:'点击修改',
        showprintercfg:true,
        printv2:"不试用",
        printerlist: [],
        cerprintdata:null,
        startprint:false,
        codeprintersize:{
          width:0,
          height:0
        },
        codeprinterformInline:{
          width:0,
          height:0,
        }
      },
      showboxsizeedit:false,
      printtest:0,
      formInline: {
        companyid: null,
        cnmodelno:'',
        cerindex:0,
        certificateTemplateId:'',
        certificateNo:'',
        vehicleNo:'',
        motorNo:'',
        standard:'国标',
        madeDate:'',
        vehicleColor:'',
        qrcode:'认证中心',
        boxcode:'不打印',
        cerdoprint:'打印',
        inputtype:'扫码输入',
        motorinputtype:'扫码输入',
        autodelnum:3,
        motorautodelnum:3,
        area:'全国',
        boxprintnum:2,
        printseal:'false',
        printwatermark:'false'
      },
      synctext:'同步数据',
      syncdisabled:false,
      syncdisabledsecondleft:30,
      editcfg:this._self.$root.$store.state.VehicleAddConfig,
      ccclist:this._self.$root.$store.state.CccList,
      vehicleColorList:[],
      savedCommonColors:[],
      //全部的合格证模板数据
      CertificateTemplateList:null,//JSON.parse(this._self.$root.$store.state.CertificateList),
      //显示在下拉框里面的模板数据
      CertificateTemplateShowList:null,
      //中文型号分类列表
      CnModelList:null,
      selectedTempIndex:0,
      MasterModelVers:null,
      showDupConfirm:false,
      showarea:true,
      showcerdiv:false,
      showcodediv:false,
      temploading: false,
      logmsg: new Array(),
      qrcodesready:false,
      vehicleaddsuccess:false,
      printover:false,
      printbtndisabled:false,
      previewcer:false,
      yiqi23:false,
      yihao:false,
      cccindex:'',
      logstyle:{
        height: "515px",//this.getlogheight(),
        marginBottom:"30px",
        marginTop:"20px",
        overflowX: "hidden",
        overflowY: "auto"
      },
      adminpwd:null,
      printcache:{
        vehicleno:null,
        printtime:new Date()
      },
      ruleInline:{
        cerindex:[
          {validator:validatecommon,required: true,  trigger: 'blur'},
        ],
        vehicleNo: [
          {required: true, message:'整车编码不能为空', trigger: 'blur'},
          //{ validator: validatevin, trigger: 'blur' },
        ],
        qrcode:[
          {required: true, message:'二维码配置不能为空', trigger: 'blur'},
        ],
        vehicleColor:[
          {required: true, message:'车辆颜色不能为空', trigger: 'blur'},
        ],
        madeDate:[
          {required: true, message:'制造日期不能为空', trigger: 'blur'},
        ],
        motorNo:[
          {required: true, message:'电动机编码不能为空', trigger: 'blur'},
          //{ validator: validatemotorno, trigger: 'blur' }
        ]
      }
    }
  },
  watch:{
    'printer.showprintercfg':{
      deep:true,
      handler: function(newV, oldV) {
        if(newV){
          this.logstyle.height = '515px'
        }
        else{
          this.logstyle.height = '705px'
          console.log(this.logstyle)
        }
      }
    },
    'formInline.vehicleNo':{
      deep:true,
      handler: function(newV, oldV) {
        if(this.formInline.inputtype == '扫码输入'){
          if(newV.length == 15){
            this.$refs['motorno'].focus()
          }
        }
      }
    }
  },
  mounted(){

    var logonInfo = this._self.$root.$store.state.LogonInfo
    this.MasterModelVers = this._self.$root.$store.state.MasterModelVerList
    console.log("车型版本数据",this.MasterModelVers)
    this.editcfg =this._self.$root.$store.state.VehicleAddConfig
    if(this.editcfg == null){
      this.editcfg = {vindelnum:3,motordelnum:3}
      this.formInline.autodelnum = this.editcfg.vindelnum
      this.formInline.motorautodelnum = this.editcfg.motordelnum
      this.$store.commit('changeVehicleConfig',this.editcfg)
    }


    this.userId = logonInfo.userid
    this.formInline.companyid = logonInfo.companyid
    this.makeTemplateList(true)
    console.log("合格证模板列表数据",this.CertificateTemplateList)
    if(this.CertificateTemplateList.length >0){
      //同步合格证数据后会自动同步ccc数据，所以if里面不需要同步ccc操作
      if(this.ccclist ==null){
        this.refreshCcclist()
      }
    }

    for(var item=0;item< this.CertificateTemplateList.length;item++){
      if(this.CertificateTemplateList[item].cerdisplay.certificateid == this.formInline.certificateTemplateId){
        this.selectedTempIndex = item+1
        break
      }
    }
    this.getPrinterdata()
  },
  components:{
    CertificatePrint2to1hao,
    CertificatePrint4to1hao,
    CertificatePrint3to23,
    CertificatePrint2to23,
    CertificatePrint4to23,
    CertificatePrint5to23,
    CertificatePrint3to1hao,
    CertificatePrintVto1hao,
      cerview: CertificatePrint,
      cerviewv2: CertificatePrintV2,
      cerviewV23: CertificatePrintV23,
      codeview:CodePrint,
      loginfobar:UserInfoTitleBar,
  },
  methods: {
    handleSubmit (name) {

      this.$refs[name].validate((valid) => {
        console.log('checkvalid='+valid)
        if (valid) {
          if(this.printer.cerprinter ==null){
            this.$Message.error('请先配置合格证打印机')
            return
          }
          if(this.formInline.boxcode == '打印'){
            if(this.printer.codeprinter ==null ||this.printer.codeprinter ==''){
              this.$Message.error('请先配置箱贴打印机')
              return
            }

/*             if(this.printer.codeprintersize ==null ||this.printer.codeprinter ==''){
              this.$Message.error('请先配置箱贴打印机')
              return
            } */
          }

          /**
           * 检查合格证车辆图片是否上传
           */
        let cerckresult = this.checkcerinfobyarea()
        if(cerckresult !=null){
          this.$Message.error(cerckresult)
          return
        }

        this.previewcer = false
        /**
         *保存自删位数的数据
         */
        if(this.editcfg.vindelnum != this.formInline.autodelnum || this.editcfg.motordelnum != this.formInline.motorautodelnum){
          this.editcfg.vindelnum = this.formInline.autodelnum
          this.editcfg.motordelnum =this.formInline.motorautodelnum
          this.$store.commit('changeVehicleConfig',this.editcfg)
        }

          var errmsg = this.checkVin()
          if(errmsg !=null){
            //规则校验失败
            this.$Modal.error({
              title:'规则校验错误',
              content:errmsg,
              okText:'我再检查下',
            })
            return
          }

          errmsg = this.checkMotor()
          if(errmsg ==null){
            //校验无误
            //this.oncheckvin(this.formInline.vehicleNo)
            this.oncheckvin(this.formInline.vehicleNo)
          }
          else{
            //规则校验失败
            this.$Modal.confirm({
              title:'规则校验提醒',
              content:errmsg,
              okText:'我再检查下',
              cancelText:'继续打印',
              onOk:()=>{
                //this.$Modal.remove()
                this.printbtndisabled = false
              },
              onCancel:()=>{
                this.oncheckvin(this.formInline.vehicleNo)
              }
            })
          }
        } else {
          this.$Message.error('请输入正确的内容')
        }
      })
    },
    updateVehicle(){
      this.postNewVehicle('update')
    },
    onprintviewqrcodefinished(){
      console.log('received event--onprintviewqrcodefinished')
      this.qrcodesready = true
      this.checkshowcerdivornot()
    },
    checkshowcerdivornot(){
      console.log('printover='+this.printover+', qrcodesready='+this.qrcodesready+ ', vehicleaddsuccess='+this.vehicleaddsuccess)
      this.appendLogmsg('printover='+this.printover+', qrcodesready='+this.qrcodesready+ ', vehicleaddsuccess='+this.vehicleaddsuccess)
      if(this.printer.printv2 =='试用'){
        if(this.vehicleaddsuccess ==true && this.printover == false){
          this.showcerdiv = true
        }
      }
      else{
        if(this.qrcodesready == true && this.vehicleaddsuccess ==true && this.printover == false){
          this.showcerdiv = true
        }
      }

    },
    checkcerinfobyarea(){
      let cerdata=this.CertificateTemplateList[this.selectedTempIndex-1].cerfulldata
      let vehicledata= this.formInline
      console.log(cerdata)
      console.log(vehicledata)
      if(vehicledata.area == '上海' || vehicledata.area =='认证中心+上海'){
        if(cerdata.picshanghaiaddr==null || cerdata.picshanghaiaddr=='null' || cerdata.picshanghaiaddr.trim().length==0){
          return "上海地区合格证需要先上传车辆实体图，请联系技术部门上传合格证车辆图片"
        }
      }
      else{
        if(cerdata.picchinaaddr==null || cerdata.picchinaaddr=='null' || cerdata.picchinaaddr.trim().length==0){
          return "合格证需要先上传车辆简图，请联系技术部门上传合格证车辆图片"
        }
      }

      return null
    },
    postNewVehicle(action){
      this.printover = false
      //修改打印数据
      var vdata =this.extend({},this.formInline)
      this.printer.cerprintdata = {cer:this.CertificateTemplateList[this.selectedTempIndex-1].cerfulldata,vehicle:vdata}
      console.log("表单数据",this.formInline)
      console.log('打印数据this.printer.cerprintdata:',this.printer.cerprintdata)
      if(action == 'addnew')
      {
        //新增的车架号，二维码需要重新生成
        //重复提交的话，车架号不变，二维码不用重新生成
        this.qrcodesready = false
        this.vehicleaddsuccess =false
      }

      this.$refs['cerviewref'].reloaddata(this.printer.cerprintdata)
      // console.info("asdasd",this.printer)
      // this.$refs['cerviewref'].reloaddata(this.CertificateTemplateList[item-1].cerfulldata.standardtype)
      //准备提交新增车辆数据
      var postdata={
          createuser:this.userId,
          modifyuser:this.userId,
          companyid:this.formInline.companyid,
          cerid:this.formInline.certificateTemplateId,
          vehicleno:this.formInline.vehicleNo,
          motorno:this.formInline.motorNo,
          vehiclecolor:this.formInline.vehicleColor,
          madedate:this.formInline.madeDate,
          receiverarea:this.formInline.area,
        }
      if(postdata.madedate ==null){
          delete postdata.madedate
        }
      var posturl=''
      if(action == 'update'){
        posturl ="/api/vehicle/update"
      }
      else{
        posturl ="/api/vehicle/addnew"
      }
      this.$axios({
        method:'post',
        url:posturl,
        data:postdata,
        headers:{'Content-Type': 'application/x-www-form-urlencoded'},
        transformRequest: function(obj) {
            var str = [];
            for(var p in obj){
                str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
            }
            return str.join("&");
        }
      }).then(function(res){
          if(res.data.resultCode ==0){
            this.$Message.success({content:'车辆数据添加成功', duration:3})
            //this.afterAddVehicle()
/*             setTimeout(()=>{
              this.showcerdiv = true
            },2000) */
            console.log('车辆数据上传成功')
            this.vehicleaddsuccess = true
            this.checkshowcerdivornot()
            this.appendLogmsg('整车数据上传成功')
          }
          else{
              console.log("上传失败",res)
              this.$Message.error({content:res.data.msg, duration:3})
              this.appendLogmsg('整车数据'+postdata.vehicleno+'上传失败'+res.data.msg)
          }
      }.bind(this));
    },
    afterAddVehicle(){
      this.printover = true
      this.printbtndisabled = false

      if(this.formInline.cerdoprint == '打印'){
        this.appendLogmsg('开始打印合格证')
        if(this.printer.printv2 == '不试用'){
          printCer("cerdiv",makewatermark(this.formInline.printwatermark,this.printer.cerprintdata.cer.watermarkid),1)
        }
        else{
          printCerWithQrcode("cerdiv",makewatermark(this.formInline.printwatermark,this.printer.cerprintdata.cer.watermarkid),1,this.qrcode(this.printer.cerprintdata).left,this.qrcode(this.printer.cerprintdata).right)
        }
      }

      this.showcerdiv =false
      if(this.formInline.boxcode == '打印'){
        this.appendLogmsg('开始打印箱贴')
        var nums=this.formInline.boxprintnum
        nums = nums==null?0:nums
        for(var i=0;i<nums;i++){
          printCode("codediv",1)
        }

      }

      //判断是否需要自动清除后几位
      if(this.formInline.inputtype == '扫码输入'){
        this.formInline.vehicleNo=''
      }
      else{
        var dellen =this.formInline.autodelnum
        if(dellen !=null &&dellen>0){
          this.formInline.vehicleNo = this.formInline.vehicleNo.slice(0,-dellen)
        }
      }
      //判断是否需要自动清除后几位
      if(this.formInline.motorinputtype == '扫码输入'){
        this.formInline.motorNo=''
      }
      else{
        var dellen =this.formInline.motorautodelnum
        if(dellen !=null &&dellen>0){
          this.formInline.motorNo = this.formInline.motorNo.slice(0,-dellen)
        }
      }

      this.$refs['vehicleno'].focus()
      this.showcerdiv = false
    },
    refreshCertificatelist(){
      console.log(this.syncdisabled)
      console.log(this._self.$root.$store.state.LogonInfo)
      this.syncdisabled = true
      this.syncdisabledsecondleft = 30
      var countDown =setInterval(() =>{
          if(this.syncdisabledsecondleft < 1){
              this.syncdisabled = false
              this.synctext = '同步数据'
              clearInterval(countDown);
          }else{
              this.syncdisabled = true
              this.synctext = '同步数据'+this.syncdisabledsecondleft+"s"
              this.syncdisabledsecondleft = this.syncdisabledsecondleft -1
          }
      },1000);
      console.log(this.syncdisabled)
      this.$axios({
        method:'post',
        url:"/api/certificatetemplate/query",
        data:{
            companyid:this._self.$root.$store.state.LogonInfo.companyid,
            pageNum:1,
            pageSize:100
        },
        headers:{'Content-Type': 'application/x-www-form-urlencoded'},
        transformRequest: function(obj) {
            var str = [];
            for(var p in obj){
                str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
            }
            return str.join("&");
        }
      }).then(function(res){
          if(res.data.resultCode ==0){
            var rspdata = res.data.data.data
            console.log("同步合格证模板rsp数据",rspdata)
            this.CertificateTemplateList=[]
            for(var item=0;item<rspdata.length;item++){
              var cer={value:null,certificateid:null,label:null,certificateno:null,standard:null}

              cer.value=item+1
              cer.certificateid=rspdata[item].id
              cer.label=rspdata[item].certificatealias
              cer.certificateno=rspdata[item].certificateno
              cer.standard= (rspdata[item].standardtype == 2 ? '企标':(rspdata[item].standardtype == 1 ? '国标21版' :(rspdata[item].standardtype == 4 ? '1号修改单' : '国标23版') ))
              this.CertificateTemplateList.push({cerdisplay:cer,cerfulldata:rspdata[item]})
            }
            this.$store.commit('changeCertificateList',this.CertificateTemplateList)
            this.$Message.success({content:'合格证数据同步完成', duration:3})
            this.formInline.cerindex =0
            console.log("合格证模板数据",this.CertificateTemplateList)
            this.makeTemplateList(false)
            this.refreshCcclist()
          }
          else{
              this.$Message.error({content:res.data.data.msg, duration:3})
          }
      }.bind(this));
    },
    refreshCcclist(){
      this.$axios({
            method:'post',
            url:"/api/ccc/query",
            data:null,
            headers:{'Content-Type': 'application/x-www-form-urlencoded'},
            transformRequest: function(obj) {
                var str = [];
                for(var p in obj){
                    str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
                }
                return str.join("&");
            }
        }).then(function(res){
            if(res.data.resultCode ==0){
                var rspdata = res.data.data
                this.ccclist =rspdata
                this.$store.commit('changeCccList',this.ccclist)
            }
            else{
                this.$Message.error({content:res.data.data.msg, duration:3})
            }
        }.bind(this));
    },
    oncheckvin(vin){
      this.printbtndisabled = true
      console.log(this.printcache)
      console.log(new Date(this.printcache.printtime).Format("HH:mm:ss"))
      console.log(new Date().Format("HH:mm:ss"))
      console.log(sysTool.getDifferDate(this.printcache.printtime,new Date(),3))
      if(vin == this.printcache.vehicleno){
        if(sysTool.getDifferDate(this.printcache.printtime,new Date(),3) <2){
          console.log('两次相差时间太短，丢弃')
          return;
        }
      }

      this.printcache.vehicleno = vin
      this.printcache.printtime = new Date()
      setTimeout(()=>{
        console.log('111this.printbtndisabled='+this.printbtndisabled)
        this.printbtndisabled = false
        console.log('222this.printbtndisabled='+this.printbtndisabled)
      },1000)
      this.appendLogmsg('开始验证整车编码:'+this.formInline.vehicleNo)
      this.$axios({
        method:'post',
        url:"/api/vehicle/check",
        data:{vehicleno:vin},
        headers:{'Content-Type': 'application/x-www-form-urlencoded'},
        transformRequest: function(obj) {
            var str = [];
            for(var p in obj){
                str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
            }
            return str.join("&");
        }
      }).then(function(res){
          console.log(res)
          if(res.data.resultCode ==0){
              if(res.config.url == "/api/vehicle/check" && res.data.data.exist==false){
                this.appendLogmsg('整车编码无'+this.formInline.vehicleNo+'重复,正在上传')
                this.postNewVehicle('addnew')
              }
              else{
                this.$Modal.confirm({
                  title: '整车编码'+this.formInline.vehicleNo+'已存在,',
                  content: '确定要覆盖并打印吗?',
                  onOk: () => {
                    this.showDupConfirm = true
                  }
                })
                // this.$Message.error('整车编码'+this.formInline.vehicleNo+'已存在,请检查确认')
                // this.showDupConfirm = true
                // this.appendLogmsg('整车编码'+this.formInline.vehicleNo+'已存在,请检查确认')
              }

          }
          else{
              this.$Message.error({content:res.data.msg, duration:3})
          }
      }.bind(this));
    },
    checkpwd(){
      if(this.adminpwd == null || this.adminpwd == ''){
        this.$Message.error({content:"请输入密码校验！",duration:5})
        return
      }
      this.$axios.post("/api/adminuser/users/check/userpwd",{userpwd: this.adminpwd})
          .then(function(res){
            if(res.data.resultCode ==0){
              // this.confirmin()
              this.postNewVehicle('update')
              this.showDupConfirm = false
            }
            else{
              this.$Message.error({content:res.data.msg, duration:3})
            }
          }.bind(this));
    },
    onTmpSelect(item){
      console.log(item)
      if(item==null ||item==''||item==0){
        return
      }
      console.log(this.CertificateTemplateShowList)
      if(item==-1){
        this.refreshCertificatelist()
      }
      else{
        this.formInline.certificateNo = this.CertificateTemplateList[item-1].cerdisplay.certificateno
        this.formInline.standard = this.CertificateTemplateList[item-1].cerdisplay.standard
        this.formInline.certificateTemplateId = this.CertificateTemplateList[item-1].cerdisplay.certificateid

        console.log("选择合格证",this.CertificateTemplateList[item-1])
        let modelverid = this.CertificateTemplateList[item-1].cerfulldata.modelverid
        this.vehicleColorList =  this.getcolorsbymodelverid(modelverid)
        console.log("颜色选择器：",this.vehicleColorList)
        if(modelverid == null || modelverid == undefined){
          this.$Modal.error({
            title:"错误提醒",
            content:"该合格证尚未绑定车型，请联系管理员在合格证模板进行车型绑定,然后才能进行打印"
          })
          return
        }
        if(this.formInline.standard == '企标'){
          this.formInline.qrcode = '厂家'
          this.onqrchange('厂家')
        }
        console.info("123213",this.CertificateTemplateList[item-1].cerfulldata.cccindex)
        console.info("123213",this.CertificateTemplateList[item-1].cerfulldata.standardtype)
        //this.CertificateTemplateList[item-1].cerfulldata.cccindex==3 &&
        if(this.CertificateTemplateList[item-1].cerfulldata.standardtype==3){
          this.yiqi23 = true
          this.cccindex = this.CertificateTemplateList[item-1].cerfulldata.cccindex
        }
        if(this.CertificateTemplateList[item-1].cerfulldata.standardtype==4){
          this.yiqi23 = true
          this.yihao = true
          this.cccindex = this.CertificateTemplateList[item-1].cerfulldata.cccindex
        }
        // this.$refs['cerviewref'].reloaddata(this.CertificateTemplateList[item-1].cerfulldata.cccindex)
      }
      this.selectedTempIndex = item
      //this.$refs['CertificatePrintForm'].validate()
    },
    onvehiclenochange(event){
      console.log(event)
      if(event.data!=null && event.data.length==15){
        if(this.formInline.inputtype == '扫码输入'){
          this.$refs['motorno'].focus()
        }
      }
    },
    onshowcerdiv(e){
      console.log(e)
      if(e){
        console.log("-----------------")
        if(this.previewcer == false){
          this.afterAddVehicle()
        }

      }
    },
    onColorChange(v){
    },
    getcolorsbymodelverid(id){
      console.log("根据moderverid获取颜色列表",id)
      console.log("根据moderverid获取颜色列表",this.MasterModelVers)
        for(let i =0;i<this.MasterModelVers.length;i++){
            if(this.MasterModelVers[i].id == id){
              console.log("根据moderverid获取颜色列表: 获取到")
                return this.MasterModelVers[i].colors.split(";")
            }
        }
        return []
    },
    printerOnclick(e){
      if(this.printer.startconfig){
        //配置修改完了，点击执行保存操作

        var printersavedata ={
          cerprinter:this.printer.cerprinter,
          codeprinter:this.printer.codeprinter,
          printerlist:this.printer.printerlist,
          printv2:this.printer.printv2,
          changemode:'manul',
          codeprintersize:this.printer.codeprintersize
        }
        this.$store.commit('changePrinterData',printersavedata)
        setCerPrinterName(this.printer.cerprinter)
        setCodePrinterName(this.printer.codeprinter)

        this.printer.startconfig = false
        this.printer.buttonText ='点击修改'
      }
      else{
        //点击开始修改配置

        //同步打印机数据
        this.printer.printerlist = getPrinters()
        //允许修改配置
        this.printer.startconfig = true
        this.printer.buttonText ='点击保存'
      }
    },
    oncerprinterchange(v){
      console.log(v)
      this.printer.cerprinter = v.value
      console.log(this.printer.cerprinter)
    },
    oncodeprinterchange(v){

      console.log(v)
      this.printer.codeprinter = v.value
      //this.showboxsizeedit = true

      console.log("this.showboxsizeedit="+this.showboxsizeedit)
    },
    onqrchange(v){
      if(v == '认证中心' ||v=='认证中心+厂家' ||v == '厂家'){
        this.formInline.area = '全国'
      }
      else if(v == '上海' || v == '认证中心+上海'){
        this.formInline.area = '上海'
      }
      else{
        this.formInline.area = '全国'
      }
    },
    onCnModelSelect(v){
      console.log(v)
      console.log(this.formInline.cnmodelno)
      this.filterTemplateList()
    },
    makeTemplateList(ifemptythenquery){
      console.log("24",this._self.$root.$store.state.CertificateList)
      this.CertificateTemplateList =this._self.$root.$store.state.CertificateList
      if(this.CertificateTemplateList ==null ||this.CertificateTemplateList =='' || this.CertificateTemplateList =='null'){
        if(ifemptythenquery){
          this.CertificateTemplateList ==[]
          this.refreshCertificatelist()
          return
        }
        else{
          this.CertificateTemplateList ==[]
        }
      }
      this.CnModelList=[]
      console.log("合格证模板列表数据",this.CertificateTemplateList)
      this.CertificateTemplateList.forEach(element => {
        if(this.CnModelList.indexOf(element.cerfulldata.cnmodelno)<=-1){
          //不存在
          this.CnModelList.push(element.cerfulldata.cnmodelno)
        }
      });

      this.filterTemplateList()
    },
    filterTemplateList(){
      console.log("123",this.CertificateTemplateList)
      if(this.formInline.cnmodelno==''){
        this.CertificateTemplateShowList = this.CertificateTemplateList
      }
      else{
        this.CertificateTemplateShowList =[]
        this.CertificateTemplateList.forEach(element=>{
          if(element.cerfulldata.cnmodelno == this.formInline.cnmodelno){
            this.CertificateTemplateShowList.push(element)
          }
        })
      }
    },
    testprint(){
      console.log(printCer("cerdiv"))
    },
    getPrinterdata(){
      //this.printer.printerlist = getPrinters()
      console.log("打印机列表数据",this.printer.printerlist)
      var printerdata = this._self.$root.$store.state.PrinterInfo
      if(printerdata !=null){
        this.printer.printerlist = printerdata.printerlist
        this.printer.cerprinter = printerdata.cerprinter
        this.printer.codeprinter = printerdata.codeprinter
        this.printer.printv2 = printerdata.printv2
        if(this.printer.printv2 == null){
          this.printer.printv2 = '不试用'
        }

        if(printerdata.changemode == null){
          printerdata.printv2 = '不试用'
          printerdata.changemode = 'auto'
          this.$store.commit('changePrinterData',printerdata)
          this.printer.printv2 = '不试用'
        }


        if(printerdata.codeprintersize!=null){
          this.printer.codeprintersize = printerdata.codeprintersize
        }

        setCerPrinterName(this.printer.cerprinter)
        setCodePrinterName(this.printer.codeprinter)
      }
      else{
        this.printer.printv2 = '不试用'
      }
    },
    oncodepreview(){
      codePreview("codediv")
      //printCode("codediv",1)
    },
    oncerpreview(){
      if(this.formInline.cerindex ==0 ||this.formInline.cerindex ==-1
        || this.formInline.vehicleColor =='' || this.formInline.madeDate==''
        || this.formInline.qrcode == ''
        || this.formInline.vehicleNo=='' || this.formInline.motorNo ==''){
        this.$Message.error({content:'请先输入车辆数据再进行预览！',duration:2})
        return
      }
      this.previewcer = true
      var vdata =this.extend({},this.formInline)
      this.printer.cerprintdata = {cer:this.CertificateTemplateList[this.selectedTempIndex-1].cerfulldata,vehicle:vdata}
      console.log("预览打印合格证数据",this.printer.cerprintdata)
      this.$refs['cerviewref'].reloaddata(this.printer.cerprintdata)
      // console.info("asdasd",this.printer)
      // this.$refs['cerviewref'].reloaddata(this.CertificateTemplateList[item-1].cerfulldata.standardtype)

      this.showcerdiv = true
/*       this.$nextTick(function (){
        cerPreview("cerdiv",makewatermark(this.formInline.printwatermark,this.printer.cerprintdata.cer.watermarkid))
      }) */

      setTimeout(()=>{
        //cerPreview("cerdiv",makewatermark(this.formInline.printwatermark,this.printer.cerprintdata.cer.watermarkid))

        //cerPreviewWithNum("cerdiv",makewatermark(this.formInline.printwatermark,this.printer.cerprintdata.cer.watermarkid),122)
      },2000)
      //cerPreview("cerdiv")
    },
    confirmpreviewtest(){
      setTimeout(()=>{
        if(this.printer.printv2 == '不试用'){
          cerPreview("cerdiv",makewatermark(this.formInline.printwatermark,this.printer.cerprintdata.cer.watermarkid))
        }
        else{
          //cerPreviewV2("cerdiv",makewatermark(this.formInline.printwatermark,this.printer.cerprintdata.cer.watermarkid))
          cerPreviewWithQrcode("cerdiv",makewatermark(this.formInline.printwatermark,this.printer.cerprintdata.cer.watermarkid),this.qrcode(this.printer.cerprintdata).left,this.qrcode(this.printer.cerprintdata).right)
        }
      },2000)
    },
    onboxpreview(){
      var vdata =this.extend({},this.formInline)
      this.printer.cerprintdata = {cer:this.CertificateTemplateList[this.selectedTempIndex-1].cerfulldata,vehicle:vdata}
      this.showcodediv = true
      //codePreview("codediv")
    },
    oncodeprintersizeconfig(){
      console.log(this.printer.codeprinterformInline.width+"::::"+this.printer.codeprinterformInline.height)
      console.log(typeof(this.printer.codeprinterformInline.width))
      if(this.printer.codeprinterformInline.width <=0 || this.printer.codeprinterformInline.height <=0){
        this.$Message.error('宽和高不能小于1')
        console.log("00000000000")
      }
      else{
        console.log("111111111111111111")
        console.log(this.printer.codeprintersize.width)
        this.printer.codeprintersize.width = this.printer.codeprinterformInline.width
        this.printer.codeprintersize.height = this.printer.codeprinterformInline.height
        this.showboxsizeedit = false
      }

    },
    onprintv2change(v){
      console.log('onprintv2change: ' + v)
    },
    printLabel(){
			   const LODOP = getLodop();
			   const arr = [
				{name:"张三",age:"20",tel:"13200000001"},
				{name:"李四",age:"19",tel:"13200000002"},
				{name:"王五",age:"21",tel:"13200000003"},
				]
			   LODOP.PRINT_INIT("");

         var printernum =LODOP.GET_PRINTER_COUNT()
         console.log(printernum)
         var i=0
         console.log("---------------")
         for(i=0;i<printernum;i++){
          var strPName=LODOP.GET_PRINTER_NAME(i)
          console.log(strPName)
         }
         console.log("---------------")
    },
    checkVin(){
      var msg='<div style="font-size:14px">根据国标规定：</div>'
      var error = false

      if(this.formInline.standard == '国标'){
        if(this.formInline.vehicleNo.length!=15){
          msg = msg + '<div style="font-size:12px;color:red;padding-left:10px">  整车编码需要15位</div>'
          error = true
        }

        return error ? msg:null
      }
      else{
        return null
      }

      //this.$Modal.warning(config)
    },
    checkMotor(){
      var msg='<div style="font-size:14px">根据国标规定：</div>'
      var error = false

      if(this.formInline.standard == '国标'){
        if(RegExp(/W*V/).test(this.formInline.motorNo) ==false){
            msg = msg + '<div style="font-size:12px;color:red;padding-left:10px">  电机号需包含功率W和电压V</div>'
            error = true
        }

        return error ? msg:null
      }
      else{
        return null
      }

      //this.$Modal.warning(config)
    },
    appendLogmsg(log){
      if(this.logmsg.length >=200){
        this.logmsg.pop()
      }
      this.logmsg.unshift('['+(new Date()).Format("HH:mm:ss")+']'+log+'\n')
    },
    qrcode(cerdata){
      let qrcode={
        left:null,
        right:null
      }

      if(cerdata.vehicle.qrcode=='认证中心'){
        qrcode.left= this.qrcode_left(cerdata)
      }
      else if(cerdata.vehicle.qrcode=='厂家'||cerdata.vehicle.qrcode=='上海'){
        qrcode.right= this.qrcode_right(cerdata)
      }
      else{
        qrcode.left= this.qrcode_left(cerdata)
        qrcode.right= this.qrcode_right(cerdata)
      }
      console.log(qrcode)
      return qrcode
    },
    qrcode_left(cerdata) {
      var prefix=''
      var suffix=''
      var cccindex = cerdata.cer.cccindex
      var url
      for(var i=0;i<this.ccclist.length;i++){
          if(this.ccclist[i].id == cccindex){
              prefix = this.ccclist[i].prefix
              suffix = this.ccclist[i].suffix
              break
          }
      }

      url = prefix+cerdata.vehicle.vehicleNo+suffix
      return url
    },
    qrcode_right(cerdata) {
        var path
        if(cerdata.vehicle.area=='上海'){
            path = "/public/#/certificate/showdetail_shanghai/"
        }
        else{
            path = "/public/#/certificate/showdetail?vinCode="
        }

        this.supportinfo = this._self.$root.$store.state.SupportInfo
        console.log(this.supportinfo)
        var url= this.supportinfo.qrcodedomain+path+cerdata.vehicle.vehicleNo


        return url
    },
  }
}
</script>

<style scoped>
    #center {
        position: fixed;
        top: 50%;
        left: 50%;
        text-align: center;
        background-color: #ffffcc;
        width:70%;
        height: 70%;
        -webkit-transform: translateX(-50%) translateY(-70%);
    }
    /*被弹出的div*/
		#testdiv{
			border: 1px blue solid;
			border-radius: 10px;
			width: 800px;
			height: 700px;
			/*让其浮在最上面*/
			position: absolute;
			display: none;
			/*设置弹出的div窗口位置*/
			left: 10%;
			top: 10%;
		}

    .downlinediv{
      width: 100%;
    }

    .input-large{
      height: 63px;
      font-size: 30px;
      display: inline-block;
      width: 100%;
      line-height: 2;
      padding: 4px 7px;
      border: 1px solid #dcdee2;
      border-radius: 4px;
      color: #515a6e;
      background-color: #fff;
      background-image: none;
      position: relative;
      cursor: text;
    }

    .input-large:hover{
      border: 1px solid #57a3f3;
    }

    .input-large:focus {
      border-color: #57a3f3;
      outline: 0;
      box-shadow: 0 0 0 2px rgba(45,140,240,.2);
  }

  #printbar {
    background: rgba(4, 150, 241, .9);
    border-radius: 4px;
    cursor: pointer;
  }

  #printbar:hover {
    background: rgba(4, 150, 241, .7);
  }

  .ivu-form-item-error .input-large:hover {
    border-color: #ed4014;
  }

  .ivu-form-item-error .input-large:focus {
    border-color: #ed4014;
  }

  .ivu-form-item-error .input-large {
      border: 1px solid #ed4014;
  }

  .ivu-form-item{
    vertical-align: middle;
  }

  .downlinediv .ivu-radio-group-item{
    margin-top: 7px;
    margin-bottom: 7px;
  }
</style>
