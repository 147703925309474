<template>
    <Form ref="certificateEditForm" :model="formInline" :rules="ruleInline"  label-position="right" style="background:lightgray;">
            <div style="height:2px"></div>
            <Card style="margin:10px 10px 10px 10px;">
                <p slot="title" align="left"><b>合格证信息</b></p>
                <Row :gutter="20">
                  <i-col span="4">
                    <FormItem prop="cnmodelno" label="中文产品型号:" >
                        <i-input type="text" v-model="formInline.cnmodelno" :maxlength=20 show-word-limit  :autofocus="true"/>
                    </FormItem>
                  </i-col>
                  <i-col span="4">
                    <FormItem prop="certificatealias" label="合格证编码别名:">
                        <i-input type="text" v-model="formInline.certificatealias" :maxlength=20 />
                    </FormItem>
                  </i-col>
                  <i-col span="4">
                    <FormItem prop="certificateno" label="产品合格证编码:" >
                        <i-input  type="text" v-model="formInline.certificateno" :maxlength=20 show-word-limit  :autofocus="true" :disabled="action=='modify'"/>
                    </FormItem>
                  </i-col>
                  <i-col span="4">
                    <FormItem prop="boxalias" label="箱贴别名(商用名):" >
                        <i-input type="text" v-model="formInline.boxalias" :maxlength=20 show-word-limit  :autofocus="true"/>
                    </FormItem>
                  </i-col>
                  <i-col span="4">
                    <FormItem prop="standardtype" label="适用标准:" >
                      <Select v-model="formInline.standardtype" @on-change="onStandardChange">
                          <Option v-for="item in standardTypeList" :value="item.value" :key="item.value">{{ item.label }}</Option>
                      </Select>
                    </FormItem>
                  </i-col>
                  <i-col span="4">
                    <FormItem prop="standardname" label="标准名称:">
                        <i-input type="text" v-model="formInline.standardname" :maxlength=30 />
                    </FormItem>
                  </i-col>
                </Row>
                <Row :gutter="20">
                  <i-col span="4">
                      <FormItem prop="cccindex" label="发证机构：">
                        <Select v-model="formInline.cccindex"  @on-change="oncccchange">
                          <Option value=0 key=0>请选择</Option>
                          <Option v-for="(item,index) in ccclist" :value="item.id" :key="index+1">{{ ccclist[index].ccccode+"("+ccclist[index].cccname+")" }}</Option>
                          <Option value=-1 key=-1 style="color:green">同步最新认证中心数据</Option>
                      </Select>
                    </FormItem>
                  </i-col>
                  <i-col span="4">
                    <FormItem prop="cccno" label="0.17.1 CCC证书编号:">
                        <i-input type="text" v-model="formInline.cccno" :maxlength=30 show-word-limit/>
                    </FormItem>
                  </i-col>
                  <i-col span="4">
                    <FormItem prop="cccver" label="0.17.2 CCC证书版本号:">
                        <i-input type="text" v-model="formInline.cccver" :maxlength=30 show-word-limit/>
                    </FormItem>
                  </i-col>
                  <i-col span="4">
                    <FormItem prop="cccdate" label="0.17.3 CCC证书发证日期:" >
                        <DatePicker type="date"  format="yyyy-MM-dd" placeholder="选择日期"  :value="formInline.cccdate" @on-change="formInline.cccdate=$event"></DatePicker>
                    </FormItem>
                  </i-col>
                  <i-col span="4">
                    <FormItem prop="productname" label="0.19 产品名称:">
                        <i-input type="text" v-model="formInline.productname" :maxlength=10 show-word-limit />
                    </FormItem>
                  </i-col>
                </Row>
            </Card>
            <Card style="margin:10px 10px 10px 10px">
                <p slot="title" align="left"><b>车辆总体信息</b></p>
                <Row :gutter="20">
                  <i-col span="8">
                    <FormItem prop="manufacturer" label="0.1 车辆制造商:" >
                        <i-input type="text" v-model="formInline.manufacturer" maxlength='30' show-word-limit />
                    </FormItem>
                  </i-col>
                  <i-col span="8">
                    <FormItem prop="producer" label="0.2 生产企业名称:">
                        <i-input type="text" v-model="formInline.producer" :maxlength=30 :show-word-limit=true />
                    </FormItem>
                  </i-col>
                  <i-col span="8">
                    <FormItem prop="produceraddr" label="0.3 生产企业地址:">
                        <i-input type="text" v-model="formInline.produceraddr" :maxlength=100 :show-word-limit=true />
                    </FormItem>
                  </i-col>
                </Row>
                <Row :gutter="20">
                  <i-col span="8">
                    <FormItem prop="cnbrand" label="0.4 车辆中文商标:">
                        <i-input type="text" v-model="formInline.cnbrand" :maxlength=100 />
                    </FormItem>
                  </i-col>
                  <i-col span="8">
                    <FormItem prop="enbrand" label="0.5 车辆英文商标:">
                        <i-input type="text" v-model="formInline.enbrand" :maxlength=20 />
                    </FormItem>
                  </i-col>
                  <i-col span="8">
                    <FormItem prop="modelno" label="0.6 产品型号:">
                        <i-input type="text" v-model="formInline.modelno" :maxlength=20 />
                    </FormItem>
                  </i-col>
                </Row>
                <Row :gutter="20">
                  <i-col span="8">
                    <FormItem prop="drivetype" label="0.7 驱动方式:">
                        <i-input type="text" v-model="formInline.drivetype" :maxlength=20 />
                    </FormItem>
                  </i-col>
                  <i-col span="8">
                    <FormItem prop="bodysize" label="0.8 长x宽x高(mm x mm x mm):">
                        <i-input type="text" v-model="formInline.bodysize" :maxlength=18 />
                    </FormItem>
                  </i-col>
                  <i-col span="8">
                    <FormItem prop="wheeltrack" label="0.9 前后轮中心距(mm):">
                        <i-input type="text" v-model="formInline.wheeltrack" :maxlength=6 />
                    </FormItem>
                  </i-col>
                </Row>
                <Row :gutter="20">
                  <i-col span="8">
                    <FormItem prop="totalweight" label="0.10 装配完整的电动车的整车质量(kg):">
                        <i-input type="text" v-model="formInline.totalweight" />
                    </FormItem>
                  </i-col>
                  <i-col span="8">
                    <FormItem prop="maxspeed" label="0.11 最高设计车速(km/h):">
                        <i-input type="text" v-model="formInline.maxspeed" />
                    </FormItem>
                  </i-col>
                  <i-col span="8">
                    <FormItem prop="cardposition" label="0.12 铭牌固定位置:">
                        <i-input type="text" v-model="formInline.cardposition" :maxlength=30 :show-word-limit=true />
                    </FormItem>
                  </i-col>
                </Row>
                <Row :gutter="20">
                  <i-col span="8">
                    <FormItem prop="codeposition" label="0.13 车架上整车编码的位置:">
                        <i-input type="text" v-model="formInline.codeposition" :maxlength=30 :show-word-limit=true />
                    </FormItem>
                  </i-col>
                  <i-col span="8">
                    <FormItem prop="driverange" label="0.15 续航里程(km):">
                        <i-input type="text" v-model="formInline.driverange" />
                    </FormItem>
                  </i-col>
                  <i-col span="8">
                    <FormItem prop="powerconsumption" label="0.16 百公里电耗(kW·h/100km):">
                        <i-input type="text" v-model="formInline.powerconsumption" />
                    </FormItem>
                  </i-col>
                </Row>
            </Card>
            <Card style="margin:10px 10px 10px 10px">
                <p slot="title" align="left"><b>产品合格证参数</b></p>
                <Row :gutter="20">
                  <i-col span="4">
                    <FormItem prop="motorproducer" label="1.1 电动机生产企业:">
                        <i-input type="text" v-model="formInline.motorproducer"  :maxlength=40 :show-word-limit=true />
                    </FormItem>
                  </i-col>
                  <i-col span="4">
                    <FormItem prop="motormodel" label="1.2 电动机型号:">
                        <i-input type="text" v-model="formInline.motormodel" :maxlength=20 :show-word-limit=true />
                    </FormItem>
                  </i-col>
                  <i-col span="4">
                    <FormItem prop="motortype" label="1.3 电动机型式:">
                        <i-input type="text" v-model="formInline.motortype" :maxlength=20 :show-word-limit=true />
                    </FormItem>
                  </i-col>
                  <i-col span="4">
                    <FormItem prop="rotationspeed" label="1.4 额定转速(r/min):">
                        <i-input type="number" v-model="formInline.rotationspeed" />
                    </FormItem>
                  </i-col>
                  <i-col span="4">
                    <FormItem prop="outputpower" label="1.5 额定连续输出功率(W):">
                        <i-input type="number" v-model="formInline.outputpower" />
                    </FormItem>
                  </i-col>
                  <i-col span="4">
                    <FormItem prop="voltage" label="1.6 额定电压(V):">
                        <i-input type="text" v-model="formInline.voltage" />
                    </FormItem>
                  </i-col>
                </Row>
                <Row :gutter="20">
                  <i-col span="6">
                    <FormItem prop="controllerproducer" label="2.1 控制器生产企业:">
                        <i-input type="text" v-model="formInline.controllerproducer" :maxlength=40 :show-word-limit=true />
                    </FormItem>
                  </i-col>
                  <i-col span="6">
                    <FormItem prop="controllerno" label="2.2 控制器型号:">
                        <i-input type="text" v-model="formInline.controllerno" :maxlength=20 :show-word-limit=true />
                    </FormItem>
                  </i-col>
                  <i-col span="6">
                    <FormItem prop="undervoltage" label="2.3 欠压保护值(V):">
                        <i-input type="text" v-model="formInline.undervoltage" :maxlength=10 show-word-limit />
                    </FormItem>
                  </i-col>
                  <i-col span="6">
                    <FormItem prop="overcurrent" label="2.4 过流保护值(A):" >
                        <i-input type="text" v-model="formInline.overcurrent" :maxlength=10 show-word-limit />
                    </FormItem>
                  </i-col>
                </Row>
                <Row :gutter="20">
                  <i-col span="6">
                    <FormItem prop="celltype" label="3.1 蓄电池类型:">
                        <i-input type="text" v-model="formInline.celltype" :maxlength=15 :show-word-limit=true />
                    </FormItem>
                  </i-col>
                  <i-col span="6">
                    <FormItem prop="cellproducer" label="3.2 蓄电池生产企业:">
                        <i-input type="text" v-model="formInline.cellproducer" :maxlength=40 :show-word-limit=true />
                    </FormItem>
                  </i-col>
                  <i-col span="6">
                    <FormItem prop="cellvolume" label="3.3 蓄电池容量(Ah):">
                        <i-input type="text" v-model="formInline.cellvolume"  />
                    </FormItem>
                  </i-col>
                  <i-col span="6">
                    <FormItem prop="cellno" label="3.4 蓄电池型号:">
                        <i-input type="text" v-model="formInline.cellno" :maxlength=15 :show-word-limit=true />
                    </FormItem>
                  </i-col>
                </Row>
                <Row :gutter="5">
                  <i-col span="3">
                    <FormItem prop="frontwheel" label="4.1 前轮轮胎规格:">
                        <i-input type="text" v-model="formInline.frontwheel" :maxlength=15 :show-word-limit=true />
                    </FormItem>
                  </i-col>
                  <i-col span="3">
                    <FormItem prop="backwheel" label="4.2 后轮轮胎规格:">
                        <i-input type="text" v-model="formInline.backwheel" :maxlength=15 :show-word-limit=true />
                    </FormItem>
                  </i-col>
                  <i-col span="2">
                    <FormItem prop="note" label="5 备注:">
                        <i-input type="text" v-model="formInline.note" :maxlength=30 show-word-limit />
                    </FormItem>
                  </i-col>
                  <i-col span="2">
                    <FormItem  label="选择水印">
                      <Select v-model="formInline.watermarkid"  @on-change="onwatermarkchange">
                          <Option value=0 key=0>请选择</Option>
                          <Option v-for="(item,index) in watermarklist" :value="item.id" :key="index+1">{{ watermarklist[index].watermarkname }}</Option>
                          <Option value=-1 key=-1 style="color:green">同步最新水印图数据</Option>
                      </Select>
                    </FormItem>
                  </i-col>
                  <i-col span="2">
                    <FormItem  label="选择检验章">
                      <Select v-model="formInline.sealaddrid"  @on-change="onsealchange">
                          <Option value=0 key=0>请选择</Option>
                          <Option v-for="(item,index) in seallist" :value="item.id" :key="index+1">{{ seallist[index].sealname }}</Option>
                          <Option value=-1 key=-1 style="color:green">同步最新印章图数据</Option>
                      </Select>
                    </FormItem>
                  </i-col>
                  <i-col span="6">
                    <FormItem prop="chargerbrand" label="5.1 充电器商标:">
                        <i-input type="text" v-model="formInline.chargerbrand" :maxlength=20 show-word-limit />
                    </FormItem>
                  </i-col>
                  <i-col span="6">
                    <FormItem prop="chargerspecs" label="5.2 充电器型号规格:">
                        <i-input type="text" v-model="formInline.chargerspecs" :maxlength=20 show-word-limit />
                    </FormItem>
                  </i-col>
                </Row>
            </Card>
            <Card style="margin:10px 10px 10px 10px;">
                <p slot="title" align="left"><b>车辆图片信息</b></p>
                <Row :gutter="20">
                  <i-col span="12" >
                    <P style="color:red">友情提示:除上海外的全国其他区域请上传简图</P>
                    <div class="demo-upload-list" >
                      <template >
                          <img ref="chinaimg" :src="'/api/files/get?imgpath='+this.formInline.picchinaaddr">
                          <div class="demo-upload-list-cover">
                              <Icon type="ios-eye-outline" @click.native="handleView('china')"></Icon>
                              <Icon type="ios-trash-outline" @click.native="doRemove('china')"></Icon>
                          </div>
                      </template>
                    </div>
                    <Upload
                      ref="uploadchina"
                      :show-upload-list="false"
                      :data="{
                        companyid: this.formInline.companyid,
                        certificateno: this.formInline.certificateno,
                        area:'china'
                      }"
                      v-bind:disabled="uploadDisable"
                      :on-success="onuploadsuccesschina"
                      :on-error="onuploaderrorchina"
                      :format="['jpg','jpeg','png']"
                      :max-size="200"
                      :on-exceeded-size="onpicexceed"
                      type="drag"
                      action="/api/files/certificate/upload"
                      style="display: inline-block;width:58px;">
                      <div style="width: 58px;height:58px;line-height: 58px;">
                          <Icon type="ios-camera" size="20"></Icon>
                      </div>
                    </Upload>
                    <P style="color:red">车辆图片(全国区域) 建议尺寸宽:400px 高:230px</P>
                  </i-col>
                  <i-col span="12">
                    <P style="color:red">友情提示:上海要求车头朝右，白色背景实物图片</P>
                    <div class="demo-upload-list" >
                      <template >
                          <img ref="shimg" :src="'/api/files/get?imgpath='+formInline.picshanghaiaddr">
                          <div class="demo-upload-list-cover">
                              <Icon type="ios-eye-outline" @click.native="handleView('shanghai')"></Icon>
                              <Icon type="ios-trash-outline" @click.native="doRemove('shanghai')"></Icon>
                          </div>
                      </template>
                    </div>
                    <Upload
                      ref="uploadsh"
                      :show-upload-list="false"
                      :data="{
                        companyid: this.formInline.companyid,
                        certificateno: this.formInline.certificateno,
                        area:'shanghai'
                      }"
                      v-bind:disabled="uploadDisable"
                      :on-success="onuploadsuccesssh"
                      :on-error="onuploaderrorsh"
                      :format="['jpg','jpeg','png']"
                      :max-size="200"
                      :on-exceeded-size="onpicexceed"
                      type="drag"
                      action="/api/files/certificate/upload"
                      style="display: inline-block;width:58px;">
                      <div style="width: 58px;height:58px;line-height: 58px;">
                          <Icon type="ios-camera" size="20"></Icon>
                      </div>
                    </Upload>
                    <P style="color:red">车辆图片(上海区域)  建议尺寸宽:400px 高:230px</P>
                  </i-col>
                </Row>
            </Card>
        <FormItem style="margin-top:15px;">
            <Button type="primary" ghost @click="handlecancel" style="width:200px">取消</Button>
            <Button type="primary" @click="handleSubmit('certificateEditForm')" style="width:200px;margin-left: 20px;">提交</Button>
        </FormItem>
        <div style="height:1px;margin-top:15px"></div>
        <Modal
        v-model="showimg"
        title="查看图片"
        >
          <img :src="'/api/files/get?imgpath='+this.viewpicurl" style="width:400px;height:300px"/>
        </Modal>
    </Form>
</template>
<script>
import { truncate } from 'fs'
import {certificateService} from '@/api/certificate.js'
    export default {
        name:'CertificateEdit',
        props:['cerid','cerdata'],
        data () {
          function trim(str) {
            if (str == null) {
                return "";
            }
            return str.replace(/^\s*(.*?)[\s\n]*$/g,'$1');
          }

          const validateinput = (rule, value, callback) => {
            if (value == '') {
              return callback(new Error('不能为空'))
            } else {
              return callback()
            }
          }

          const validateccc = (rule, value, callback) => {
            console.log(this.formInline.standardtype+":"+value)
            if (this.formInline.standardtype ==1 && (value == '' ||value ==null)) {
              return callback(new Error('不能为空'))
            } else {
              return callback()
            }
          }

          const validatecccindex = (rule, value, callback) => {
            console.log(this.formInline.standardtype+":"+value)
            if (this.formInline.standardtype ==1 && (value <=0 ||value ==null)) {
              return callback(new Error('不能为空'))
            } else {
              return callback()
            }
          }

          const isDecimal = (rule, value, callback) => {
            const age= /^(([1-9]\d{0,4})|0)(\.\d{0,3})?$/
            if (!age.test(value)) {
              return callback(new Error('只能为整数或小数'))
            }else{
              return callback()
            }
          }
            return {
                action:this.$route.params['action'],
                formInline: {
                    id:null,
                    companyid:this._self.$root.$store.state.LogonInfo.companyid,
                    cnmodelno:'',
                    boxalias:'',
                    certificateno: '',
                    certificatealias: '',
                    standardtype:'',
                    standardname:'',
                    manufacturer:null,
                    producer:null,
                    produceraddr:null,
                    cnbrand:null,
                    enbrand:null,
                    modelno:null,
                    drivetype:null,
                    bodysize:null,
                    wheeltrack:null,
                    totalweight:null,
                    maxspeed:null,
                    cardposition:null,
                    codeposition: null,
                    driverange: null,
                    powerconsumption: null,
                    cccno: null,
                    cccver: null,
                    cccdate: null,
                    cccindex:0,
                    productname:null,
                    motorproducer: null,
                    motormodel: null,
                    motortype: null,
                    rotationspeed: null,
                    outputpower:　null,
                    voltage:　null,
                    controllerproducer: null,
                    controllerno: null,
                    undervoltage: null,
                    overcurrent: null,
                    cellproducer:　null,
                    celltype: null,
                    cellvolume: null,
                    cellno: null,
                    frontwheel: null,
                    backwheel: null,
                    chargerbrand:null,
                    chargerspecs:null,
                    note: null,
                    watermarkid:0,
                    sealaddrid:0,
                    picchinaaddr:null,
                    picshanghaiaddr:null,
                    createuser:this._self.$root.$store.state.LogonInfo.companyid,
                    modifyuser:this._self.$root.$store.state.LogonInfo.companyid,
                },
                uploadDisable:true,
                standardTypeList:[
                  {label:'国标',value:1},
                  {label:'企标',value:2},
                ],
                ccclist:this._self.$root.$store.state.CccList,
                watermarklist:this._self.$root.$store.state.WatermarkList,
                seallist:this._self.$root.$store.state.SealList,
                ruleInline: {
                  cnmodelno: [
                    {required: true, message: '不能为空!', trigger: 'blur'},
                  ],
                  boxalias: [
                    {required: true, message: '不能为空!', trigger: 'blur'},
                  ],
                  certificateno: [
                    { required: true, message: '不能为空!', trigger: 'blur' },
                    { type: 'string', min: 8, max:20, message: '合格证号码需大于8位不超过20位', trigger: 'blur' }
                  ],
                  certificatealias: [
                    { required: true, message: '不能为空!', trigger: 'blur' }
                  ],
                  standardtype: [
                    {validator: validateinput, required:true, trigger: 'blur'},
                  ],
                  standardname: [
                    { required: true, message: '不能为空!', trigger: 'blur' }
                  ],
                  manufacturer: [
                    { required: true, message: '不能为空!', trigger: 'blur' }
                  ],
                  producer: [
                    { required: true, message: '不能为空!', trigger: 'blur' }
                  ],
                  produceraddr: [
                    { required: true, message: '不能为空!', trigger: 'blur' }
                  ],
                  cnbrand: [
                    { required: false, message: '不能为空!', trigger: 'blur' }
                  ],
                  enbrand: [
                    { required: false, message: '不能为空!', trigger: 'blur' }
                  ],
                  modelno: [
                    { required: true, message: '不能为空!', trigger: 'blur' }
                  ],
                  drivetype: [
                    { required: true, message: '不能为空!', trigger: 'blur' }
                  ],
                  bodysize: [
                    { required: true, message: '不能为空!', trigger: 'blur' }
                  ],
                  wheeltrack: [
                    { required: true, message: '不能为空!', trigger: 'blur' }
                  ],
                  totalweight: [
                    {validator: validateinput, required:true, trigger: 'blur'},
                    { validator: isDecimal, trigger: 'blur' }
                    //{ required: true, message: '不能为空!', trigger: 'blur' },
                    //{ message: '只能是小数或整数', trigger:'blur', pattern:/^(([1-9]\d{0,3})|0)(\.\d{0,2})?$/}
                  ],
                  maxspeed: [
                    {validator: validateinput, required:true, trigger: 'blur'},
                    { validator: isDecimal, trigger: 'blur' }
                  ],
                  cardposition: [
                    { required: true, message: '不能为空!', trigger: 'blur' }
                  ],
                  codeposition: [
                    { required: true, message: '不能为空!', trigger: 'blur' }
                  ],
                  driverange: [
                    {validator: validateinput, required:true, trigger: 'blur'},
                    { validator: isDecimal, trigger: 'blur' }
                  ],
                  //有的电耗值为 <=1.2
                  powerconsumption: [
                    { required: true, message: '不能为空!', trigger: 'blur' }
                  ],
                  cccno: [
                    { validator:validateccc, trigger: 'blur' }
                  ],
                  cccver: [
                    { validator:validateccc, trigger: 'blur' }
                  ],
                  cccdate: [
                    { validator:validateccc, trigger: 'blur' }
                  ],
                  cccindex: [
                    { validator:validatecccindex, trigger: 'blur' }
                  ],
                  motorproducer: [
                    { required: true, message: '不能为空!', trigger: 'blur' }
                  ],
                  motormodel: [
                    { required: true, message: '不能为空!', trigger: 'blur' }
                  ],
                  motortype: [
                    { required: true, message: '不能为空!', trigger: 'blur' }
                  ],
                  rotationspeed: [
                    {validator: validateinput, required:true, trigger: 'blur'},
                  ],
                  outputpower: [
                    {validator: validateinput, required:true, trigger: 'blur'},
                  ],
                  voltage: [
                    {validator: validateinput, required:true, trigger: 'blur'},
                    { validator: isDecimal, trigger: 'blur' }
                  ],
                  controllerproducer: [
                    { required: true, message: '不能为空!', trigger: 'blur' }
                  ],
                  controllerno: [
                    { required: true, message: '不能为空!', trigger: 'blur' }
                  ],
                  undervoltage: [
                    { required: true, message: '不能为空!', trigger: 'blur' }
                  ],
                  overcurrent: [
                    { required: true, message: '不能为空!', trigger: 'blur' }
                  ],
                  cellproducer: [
                    { required: true, message: '不能为空!', trigger: 'blur' }
                  ],
                  celltype: [
                    { required: true, message: '不能为空!', trigger: 'blur' }
                  ],
                  cellvolume: [
                    { required: true, message: '不能为空!', trigger: 'blur' }
                  ],
                  cellno: [
                    { required: true, message: '不能为空!', trigger: 'blur' }
                  ],
                  frontwheel: [
                    { required: true, message: '不能为空!', trigger: 'blur' }
                  ],
                  backwheel: [
                    { required: true, message: '不能为空!', trigger: 'blur' }
                  ],
                  note: [
                    { required: false, message: '请输入合格证别名', trigger: 'blur' }
                  ]
                },
            viewpicurl:null,
            showimg:false
            }
        },
        watch:{
          'formInline.certificateno': function (val) {
               /* ... */
               if(val==''){
                 this.uploadDisable = true
               }
               else{
                 this.uploadDisable = false
               }
            },
          'formInline.picchinaaddr':  {
              handler:function(val){
                this.$refs['chinaimg'].src ="/api/files/get?imgpath="+val
              }
            },
          'formInline.picshanghaiaddr':function (val, oldVal) {
              this.$refs['shimg'].src ="/api/files/get?imgpath="+val
            },
        },
        mounted(){
          //console.log(this.action)
          console.log(this.ccclist)
          if(this.ccclist ==null){
            this.refreshCccList()
          }

          if(this.watermarklist ==null){
            this.refreshPicList('watermark')
          }

          if(this.seallist ==null){
            this.refreshPicList('seal')
          }

          if(this.action == 'add'){
            //add

          }
          else if(this.action == 'modify'){
            //modify
            this.formInline = this._self.$root.$store.state.CertificateEditData
            console.log(this.formInline.standardtype)
            //console.log(localStorage.getItem('certificateEditData'))
          }
          else if(this.action == 'copy'){
            //copy
            this.formInline = this._self.$root.$store.state.CertificateEditData
            this.formInline.certificateno=''
            this.formInline.certificatealias=''
            this.formInline.cnbrand = ''
            this.formInline.enbrand = ''
            this.formInline.picchinaaddr = null
            this.formInline.picshanghaiaddr = null
            this.uploadDisable = false
          }
        },
        methods: {
            handlecancel(){
              this.$router.go(-1)
            },
            handleSubmit(name) {
              console.log(this.ccclist)
              console.log(this._self.$root.$store.state.CccList)
              console.log(this.formInline.picchinaaddr)

              this.$refs[name].validate(async (valid) => {
                  if (valid) {
                    if(this.formInline.picchinaaddr==null || this.formInline.picchinaaddr == 'null' || this.formInline.picchinaaddr == ''){
                        this.$Message.error({content:'车辆简图必须上传!',duration:3})
                        return
                    }
                    var url=''
                    var postdata=this.extend({},this.formInline)
                    if(this.formInline.cccdate ==null){
                      delete postdata.cccdate
                    }
                    if(this.formInline.sealaddrid ==null){
                      postdata.sealaddrid =0
                    }
                    if(this.formInline.watermarkid ==null){
                      postdata.watermarkid =0
                    }

                    let result=null
                    if(this.action == 'add' || this.action =='copy'){
                      delete postdata.id
                      delete postdata.createtime
                      delete postdata.modifytime
                      certificateService.create(postdata)
                      .then(({ data }) => {
                        console.log(data)
                        if (data.resultCode == 0) {
                          this.$Message.success({content:data.msg, duration:3, closable:true})
                          this.$router.go(-1)
                        }
                        else{
                          this.$Message.error({content:data.msg, duration:3})
                        }
                      })
                    }
                    else if(this.action =='modify'){
                      certificateService.update(postdata)
                      .then(({ data }) => {
                        if (data.resultCode == 0) {
                          this.$Message.success({content:data.msg, duration:3, closable:true})
                          this.$router.go(-1)
                        }
                        else{
                          this.$Message.error({content:data.msg, duration:3})
                        }
                      })
                    }
                  } else {
                      this.$Message.error('填写不正确,请检查错误项重新填写!');
                  }
              })
            },
            oncccchange(v){
              if(v==-1){
                this.refreshCccList()
              }
            },
            onwatermarkchange(v){
              if(v==-1){
                this.refreshPicList('watermark')
                this.formInline.watermarkid = 0
              }
            },
            onsealchange(v){
              if(v==-1){
                this.refreshPicList('seal')
                this.formInline.sealaddrid = 0
              }
            },
            onStandardChange(v) {
              console.log(v)
              if(v == 1){
                this.formInline.standardname = 'GB 17761-2018《电动自行车安全技术规范》'
              }
              else{
                this.formInline.standardname = ''
              }
            },
            onuploadsuccesschina(response, file){
              this.formInline.picchinaaddr = response.data.filepath
              this.$refs['chinaimg'].src ="/api/files/get?imgpath="+this.formInline.picchinaaddr
              this.$Message.success('上传成功')
            },
            onuploaderrorchina(response, file){
              console.log(response)
              console.log(file)
            },
            onuploadsuccesssh(response, file){
              console.log(response)
              console.log(file)
              this.formInline.picshanghaiaddr = response.data.filepath
              this.$refs['shimg'].src ="/api/files/get?imgpath="+this.formInline.picshanghaiaddr
              this.$Message.success('上传成功')
            },
            onuploaderrorsh(response, file){
              console.log(response)
              console.log(file)
            },
            onpicexceed(f){
              this.$Message.error({content:'上传失败，文件超出200k！',duration:3})
            },
            handleView (area) {
              console.log('handleView')
              console.log(area)
              console.log(this.formInline.picchinaaddr)
              if(area =='shanghai'){
                this.viewpicurl = this.formInline.picshanghaiaddr
              }
              else{
                this.viewpicurl = this.formInline.picchinaaddr
              }
              this.showimg = true
            },
            doRemove(area){
              this.$Modal.confirm({
                content:'确定要删除图片吗？',
                onOk:()=>{
                  this.handleRemove(area)
                }
              })
            },
            handleRemove (area) {
              var picpath
              if(area == 'shanghai'){
                picpath = this.formInline.picshanghaiaddr
              }
              else if(area =='china'){
                picpath = this.formInline.picchinaaddr
              }

              this.$Message.success({content:res.data.msg, duration:3, closable:true})

              if(area == 'shanghai'){
                this.formInline.picshanghaiaddr =null
                this.$refs['shimg'].src =null
              }
              else{
                this.formInline.picchinaaddr =null
                this.$refs['chinaimg'].src =null
              }

              /* this.$axios({
                method:'post',
                url:'/api/files/delete',
                data:{path:picpath},
                headers:{'Content-Type': 'application/x-www-form-urlencoded'},
                transformRequest: function(obj) {
                    var str = [];
                    for(var p in obj){
                        str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
                    }
                    return str.join("&");
                }
              }).then(function(res){
                if(res.data.resultCode ==0){
                  this.$Message.success({content:res.data.msg, duration:3, closable:true})

                  if(area == 'shanghai'){
                    this.formInline.picshanghaiaddr =null
                    this.$refs['shimg'].src =null
                  }
                  else{
                    this.formInline.picchinaaddr =null
                    this.$refs['chinaimg'].src =null
                  }
                }
                else{
                  this.$Message.error({content:res.data.msg, duration:3})
                }
              }.bind(this)); */
            },
            refreshCccList(){
                axios.post("/api/ccc/query")
                .then(function(res){
                    if(res.data.resultCode ==0){
                        var rspdata = res.data.data
                        this.ccclist = JSON.parse(JSON.stringify(rspdata))
                        this.$store.commit('changeCccList',this.ccclist)
                        this.$Message.success({content:res.data.msg, duration:3})
                        if(this.cccindex<0){
                          this.cccindex = 0
                        }
                    }
                    else{
                        this.$Message.error({content:res.data.data.msg, duration:3})
                    }
                }.bind(this));
            },
            refreshPicList(name){
              axios.post("/api/"+name+"/query",{companyid:this.formInline.companyid})
              .then(function(res){
                    if(res.data.resultCode ==0){
                        var rspdata = res.data.data
                        if(name =='watermark'){
                          this.watermarklist =rspdata
                          this.$store.commit('changeWatermarkList',this.watermarklist)
                          if(this.formInline.watermarkid<0){
                            this.watermarkid = 0
                          }
                        }
                        else if(name =='seal'){
                          this.seallist =rspdata
                          this.$store.commit('changeSealList',this.seallist)
                          if(this.formInline.sealaddrid<0){
                            this.sealaddrid = 0
                          }
                        }
                        this.$Message.success({content:res.data.msg, duration:3})

                    }
                    else{
                        this.$Message.error({content:res.data.data.msg, duration:3})
                    }
                }.bind(this));
            },

        }
    }
</script>

<style>
    .demo-upload-list{
        display: inline-block;
        width: 60px;
        height: 60px;
        text-align: center;
        line-height: 60px;
        border: 1px solid transparent;
        border-radius: 4px;
        overflow: hidden;
        background: #fff;
        position: relative;
        box-shadow: 0 1px 1px rgba(0,0,0,.2);
        margin-right: 4px;
    }
    .demo-upload-list img{
        width: 100%;
        height: 100%;
    }
    .demo-upload-list-cover{
        display: none;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background: rgba(0,0,0,.6);
    }
    .demo-upload-list:hover .demo-upload-list-cover{
        display: block;
    }
    .demo-upload-list-cover i{
        color: #fff;
        font-size: 20px;
        cursor: pointer;
        margin: 0 2px;
    }
</style>
