<template>
  <Modal :value="visibility"  width="100%" height="100%" scrollable @on-ok="handleSubmit('certificateEditForm')" @on-cancel="handlecancel">
    <p slot="header" style="color:#f60;text-align:left">
        <span >编辑合格证模板</span>
    </p>
    <Form ref="certificateEditForm" :model="formInline" :rules="ruleInline"  label-position="right" style="background:lightgray;" :key="abc">
            <div style="height:2px"></div>
            <Card style="margin:10px 10px 10px 10px;">
                <template #title>
<!--                  <p>-->
<!--                    <b>合格证信息</b>-->
<!--                  </p>-->
<!--                  <div>-->
<!--                    <Form>-->
<!--                      <FormItem prop="cccindex" label="发证机构：">-->
<!--                        <Select v-model="formInline.cccindex"  @on-change="oncccchange">-->
<!--                          <Option value=0 key=0>请选择</Option>-->
<!--                          <Option v-for="(item,index) in ccclist" :value="item.id" :key="index+1">{{ ccclist[index].ccccode+"("+ccclist[index].cccname+")" }}</Option>-->
<!--                          <Option value=-1 key=-1 style="color:green">同步最新认证中心数据</Option>-->
<!--                        </Select>-->
<!--                      </FormItem>-->
<!--                    </Form>-->
<!--                  </div>-->
                  <Row :gutter="10">
                    <i-col>
                      <p>
                        <b>合格证信息</b>
                      </p>
                    </i-col>
<!--                    <i-col >-->
<!--                      <Form :inline="true" :label-width="100" style="height: 30px">-->
<!--                        <FormItem label="发证机构：">-->
<!--                          <Select v-model="formInline.cccindex"  @on-change="oncccchange">-->
<!--                            <Option value=0 key=0>请选择</Option>-->
<!--                            <Option v-for="(item,index) in ccclist" :value="item.id" :key="index+1">{{ ccclist[index].ccccode+"("+ccclist[index].cccname+")" }}</Option>-->
<!--                            <Option value=-1 key=-1 style="color:green">同步最新认证中心数据</Option>-->
<!--                          </Select>-->
<!--                        </FormItem>-->
<!--                        <FormItem label="整车编码:" >-->
<!--                          <i-input  type="text" v-model="formInline.certificateno" placeholder="请输入抓取合格证信息的整车编码"/>-->
<!--                        </FormItem>-->
<!--                        <Button type="primary" size="default"  @click="grab" >抓取信息</Button>-->
<!--                      </Form>-->
<!--                    </i-col>-->
<!--                    <i-col>-->

<!--                    </i-col>-->
<!--                    <i-col> <Button type="primary" size="default"  @click="grab" >抓取信息</Button></i-col>-->
                  </Row>
                </template>
              <template #extra>
                <Row :gutter="20" style="margin: -5px auto ">
                  <i-col >
                    <Form :inline="true" :label-width="100" >
                      <FormItem label="发证机构：">
                        <Select v-model="formInline.cccindex"  @on-change="oncccchange">
                          <Option value=0 key=0>请选择</Option>
                          <Option v-for="(item,index) in ccclist" :value="item.id" :key="index+1">{{ ccclist[index].ccccode+"("+ccclist[index].cccname+")" }}</Option>
                          <Option value=-1 key=-1 style="color:green">同步最新认证中心数据</Option>
                        </Select>
                      </FormItem>
                      <FormItem label="整车编码:" >
                        <i-input  type="text" v-model="formInline.vehicleNo" placeholder="请输入抓取合格证信息的整车编码"/>
                      </FormItem>
                      <Button type="primary" size="default"  @click="grab" >抓取信息</Button>
                    </Form>
                  </i-col>
                  <i-col>

                  </i-col>
                  <!--                    <i-col> <Button type="primary" size="default"  @click="grab" >抓取信息</Button></i-col>-->
                </Row>
              </template>
<!--              <template #title>-->
<!--                <p>-->
<!--                  <b>合格证信息</b>-->
<!--                </p>-->
<!--              </template>-->

<!--                <p slot="title"  style="height: 100px">-->
<!--                  <b>合格证信息</b>-->
<!--                  <span style="float: right">-->
<!--                    <FormItem prop="cccindex" label="发证机构：">-->
<!--                      <Select v-model="formInline.cccindex"  @on-change="oncccchange">-->
<!--                        <Option value=0 key=0>请选择</Option>-->
<!--                        <Option v-for="(item,index) in ccclist" :value="item.id" :key="index+1">{{ ccclist[index].ccccode+"("+ccclist[index].cccname+")" }}</Option>-->
<!--                        <Option value=-1 key=-1 style="color:green">同步最新认证中心数据</Option>-->
<!--                      </Select>-->
<!--                    </FormItem>-->
<!--                  <Button type="primary" size="large"  @click="grab" >抓取信息</Button>-->
<!--                  </span>-->

<!--                </p>-->
                <Row :gutter="20">
                  <!--
                  <i-col span="4">
                    <FormItem prop="cnmodelno" label="中文产品型号:" >
                        <i-input type="text" v-model="formInline.cnmodelno" :maxlength=20 show-word-limit  :autofocus="true"/>
                    </FormItem>
                  </i-col>
                  <i-col span="4">
                    <FormItem prop="certificatealias" label="合格证编码别名:">
                        <i-input type="text" v-model="formInline.certificatealias" :maxlength=20 />
                    </FormItem>
                  </i-col>
                  -->
                  <i-col span="4">
                    <FormItem prop="modelids" label="车型选择:">
                      <Cascader v-model="formInline.modelids"  :data="MasterModelDatalist" @on-change="onmodelverchange"></Cascader>
                    </FormItem>
                  </i-col>
                  <i-col span="4">
                    <FormItem prop="certificateno" label="产品合格证编码:" >
                        <i-input  type="text" v-model="formInline.certificateno" :maxlength=20 show-word-limit  :autofocus="true" :disabled="action=='modify'"/>
                    </FormItem>
                  </i-col>
                  <i-col span="4">
                    <FormItem prop="boxalias" label="箱贴别名(商用名):" >
                        <i-input type="text" v-model="formInline.boxalias" :maxlength=20 show-word-limit  :autofocus="true"/>
                    </FormItem>
                  </i-col>
                  <i-col span="4">
                    <FormItem prop="standardtype" label="适用标准:" >
                      <Select v-model="formInline.standardtype" @on-change="onStandardChange">
                          <Option v-for="item in standardTypeList" :value="item.value" :key="item.value">{{ item.label }}</Option>
                      </Select>
                    </FormItem>
                  </i-col>
                  <i-col span="20" v-if="formInline.standardtype=='3'  && (formInline.cccindex=='3' || formInline.cccindex=='5') ">
                    <FormItem prop="standardname2" label="本产品经过检验,符合:" >
                      <CheckboxGroup v-model="formInline.standardname2">
                        <Checkbox label="GB17761-2018《电动自行车安全技术规范》"></Checkbox>
                        <Checkbox label="GB42295-2022《电动自行车电气安全要求》"></Checkbox>
                        <Checkbox label="GB42296-2022《电动自行车用充电器安全技术要求》"></Checkbox>
                      </CheckboxGroup>
                      <span>的要求,特此证明。</span>
                    </FormItem>
                  </i-col>
                  <i-col span="20" v-if="formInline.standardtype=='4'  && (formInline.cccindex=='3') ">
                    <FormItem prop="standardname2" label="本产品经过检验,符合:" >
                      <CheckboxGroup v-model="formInline.standardname2">
                        <Checkbox label="GB 17761-2018《电动自行车安全技术规范》"></Checkbox>
                        <Checkbox label="GB 42295-2022《电动自行车电气安全要求》及第1号修改单"></Checkbox>
                        <Checkbox label="GB 42296-2022《电动自行车用充电器安全技术要求》及第1号修改单" v-if="formInline.celltype!='锂电'"></Checkbox>
                        <Checkbox label="GB 42296-2022《电动自行车用充电器安全技术要求》" v-else></Checkbox>
                        <Checkbox label="GB 43854-2024《电动自行车用锂离子蓄电池安全技术规范》"></Checkbox>
                      </CheckboxGroup>
                      <span>的要求,特此证明。</span>
                    </FormItem>
                  </i-col>
                  <i-col span="4" v-else>
                    <FormItem prop="standardname" label="标准名称:">
                      <i-input type="text" v-model="formInline.standardname" :maxlength=500 />
                    </FormItem>
                  </i-col>
                </Row>
                <Row :gutter="20">
                  <i-col span="4">
                      <FormItem prop="cccindex" label="发证机构：">
<!--                        {{formInline.cccindex}}-->
                        <Select v-model="formInline.cccindex"  @on-change="oncccchange">
                          <Option value=0 key=0>请选择</Option>
                          <Option v-for="(item,index) in ccclist" :value="item.id" :key="index+1">{{ ccclist[index].ccccode+"("+ccclist[index].cccname+")" }}</Option>
                          <Option value=-1 key=-1 style="color:green">同步最新认证中心数据</Option>
                      </Select>
                    </FormItem>
                  </i-col>
                  <i-col span="4">
                    <FormItem prop="cccno" label="0.17.1 CCC证书编号:">
                        <i-input type="text" v-model="formInline.cccno" :maxlength=30 show-word-limit/>
                    </FormItem>
                  </i-col>
                  <i-col span="4">
                    <FormItem prop="cccver" label="0.17.2 CCC证书版本号:">
                        <i-input type="text" v-model="formInline.cccver" :maxlength=30 show-word-limit/>
                    </FormItem>
                  </i-col>
                  <i-col span="4">
                    <FormItem prop="cccdate" label="0.17.3 CCC证书发证日期:" >
                        <DatePicker type="date"  format="yyyy-MM-dd" placeholder="选择日期"  :value="formInline.cccdate" @on-change="formInline.cccdate=$event"></DatePicker>
                    </FormItem>
                  </i-col>
                  <i-col span="4">
                    <FormItem prop="productname" label="0.19 产品名称:">
                        <i-input type="text" v-model="formInline.productname" :maxlength=30 show-word-limit />
                    </FormItem>
                  </i-col>
                </Row>
            </Card>
            <Card style="margin:10px 10px 10px 10px">
                <p slot="title" align="left"><b>车辆总体信息</b></p>
                <Row :gutter="20">
                  <i-col span="8">
                    <FormItem prop="manufacturer" label="0.1 车辆制造商:" >
                        <i-input type="text" v-model="formInline.manufacturer" maxlength='50' show-word-limit />
                    </FormItem>
                  </i-col>
                  <i-col span="8">
                    <FormItem prop="producer" label="0.2 生产企业名称:">
                        <i-input type="text" v-model="formInline.producer" :maxlength=50 :show-word-limit=true />
                    </FormItem>
                  </i-col>
                  <i-col span="8">
                    <FormItem prop="produceraddr" label="0.3 生产企业地址:">
                        <i-input type="text" v-model="formInline.produceraddr" :maxlength=200 :show-word-limit=true />
                    </FormItem>
                  </i-col>
                </Row>
                <Row :gutter="20">
                  <i-col span="8">
                    <FormItem prop="cnbrand" label="0.4 车辆中文商标:">
                        <i-input type="text" v-model="formInline.cnbrand" :maxlength=100 />
                    </FormItem>
                  </i-col>
                  <i-col span="8">
                    <FormItem prop="enbrand" label="0.5 车辆英文商标:">
                        <i-input type="text" v-model="formInline.enbrand" :maxlength=20 />
                    </FormItem>
                  </i-col>
                  <i-col span="8">
                    <FormItem prop="modelno" label="0.6 产品型号:">
                        <i-input type="text" v-model="formInline.modelno" :maxlength=20 />
                    </FormItem>
                  </i-col>
                </Row>
                <Row :gutter="20">
                  <i-col span="8">
                    <FormItem prop="drivetype" label="0.7 驱动方式:">
                        <i-input type="text" v-model="formInline.drivetype" :maxlength=20 />
                    </FormItem>
                  </i-col>
                  <i-col span="8">
                    <FormItem prop="bodysize" label="0.8 长x宽x高(mm x mm x mm):">
                        <i-input type="text" v-model="formInline.bodysize" :maxlength=18 />
                    </FormItem>
                  </i-col>
                  <i-col span="8">
                    <FormItem prop="wheeltrack" label="0.9 前后轮中心距(mm):">
                        <i-input type="text" v-model="formInline.wheeltrack" :maxlength=6 />
                    </FormItem>
                  </i-col>
                </Row>
                <Row :gutter="20">
                  <i-col span="8">
                    <FormItem prop="totalweight" label="0.10 装配完整的电动车的整车质量(kg):">
                        <i-input type="text" v-model="formInline.totalweight" />
                    </FormItem>
                  </i-col>
                  <i-col span="8">
                    <FormItem prop="maxspeed" label="0.11 最高设计车速(km/h):">
                        <i-input type="text" v-model="formInline.maxspeed" />
                    </FormItem>
                  </i-col>
                  <i-col span="8">
                    <FormItem prop="cardposition" label="0.12 铭牌固定位置:">
                        <i-input type="text" v-model="formInline.cardposition" :maxlength=30 :show-word-limit=true />
                    </FormItem>
                  </i-col>
                </Row>
                <Row :gutter="20">
                  <i-col span="8">
                    <FormItem prop="codeposition" label="0.13 车架上整车编码的位置:">
                        <i-input type="text" v-model="formInline.codeposition" :maxlength=30 :show-word-limit=true />
                    </FormItem>
                  </i-col>
                  <i-col span="8">
                    <FormItem prop="driverange" label="0.15 续航里程(km):">
                        <i-input type="text" v-model="formInline.driverange" />
                    </FormItem>
                  </i-col>
                  <i-col span="8">
                    <FormItem prop="powerconsumption" label="0.16 百公里电耗(kW·h/100km):">
                        <i-input type="text" v-model="formInline.powerconsumption" />
                    </FormItem>
                  </i-col>
                </Row>
            </Card>
            <Card style="margin:10px 10px 10px 10px">
                <p slot="title" align="left"><b>产品合格证参数</b></p>
                <Row :gutter="20">
                  <i-col span="4">
                    <FormItem prop="motorproducer" label="1.1 电动机生产企业:">
                        <i-input type="text" v-model="formInline.motorproducer"  :maxlength=50 :show-word-limit=true />
                    </FormItem>
                  </i-col>
                  <i-col span="4">
                    <FormItem prop="motormodel" label="1.2 电动机型号:">
                        <i-input type="text" v-model="formInline.motormodel" :maxlength=20 :show-word-limit=true />
                    </FormItem>
                  </i-col>
                  <i-col span="4">
                    <FormItem prop="motortype" label="1.3 电动机型式:">
                        <i-input type="text" v-model="formInline.motortype" :maxlength=20 :show-word-limit=true />
                    </FormItem>
                  </i-col>
                  <i-col span="4">
                    <FormItem prop="rotationspeed" label="1.4 额定转速(r/min):">
                        <i-input type="number" v-model="formInline.rotationspeed" />
                    </FormItem>
                  </i-col>
                  <i-col span="4">
                    <FormItem prop="outputpower" label="1.5 额定连续输出功率(W):">
                        <i-input type="number" v-model="formInline.outputpower" />
                    </FormItem>
                  </i-col>
                  <i-col span="4">
                    <FormItem prop="voltage" label="1.6 额定电压(V):">
                        <i-input type="text" v-model="formInline.voltage" />
                    </FormItem>
                  </i-col>
                </Row>
                <Row :gutter="20">
                  <i-col span="6">
                    <FormItem prop="controllerproducer" label="2.1 控制器生产企业:">
                        <i-input type="text" v-model="formInline.controllerproducer" :maxlength=50 :show-word-limit=true />
                    </FormItem>
                  </i-col>
                  <i-col span="6">
                    <FormItem prop="controllerno" label="2.2 控制器型号:">
                        <i-input type="text" v-model="formInline.controllerno" :maxlength=100 :show-word-limit=true />
                    </FormItem>
                  </i-col>
                  <i-col span="6">
                    <FormItem prop="undervoltage" label="2.3 欠压保护值(V):">
                        <i-input type="text" v-model="formInline.undervoltage" :maxlength=10 show-word-limit />
                    </FormItem>
                  </i-col>
                  <i-col span="6">
                    <FormItem prop="overcurrent" label="2.4 过流保护值(A):" >
                        <i-input type="text" v-model="formInline.overcurrent" :maxlength=10 show-word-limit />
                    </FormItem>
                  </i-col>
                </Row>
                <Row :gutter="20">
                  <i-col span="6">
                    <FormItem prop="celltype" label="3.1 蓄电池类型:">
                        <i-input type="text" v-model="formInline.celltype" :maxlength=15 :show-word-limit=true />
                    </FormItem>
                  </i-col>
                  <i-col span="6">
                    <FormItem prop="cellproducer" label="3.2 蓄电池生产企业:">
                        <i-input type="text" v-model="formInline.cellproducer" :maxlength=50 :show-word-limit=true />
                    </FormItem>
                  </i-col>
                  <i-col span="6">
                    <FormItem prop="cellvolume" label="3.3 蓄电池容量(Ah):">
                        <i-input type="text" v-model="formInline.cellvolume"  />
                    </FormItem>
                  </i-col>
                  <i-col span="6">
                    <FormItem prop="cellno" label="3.4 蓄电池型号:">
                        <i-input type="text" v-model="formInline.cellno" :maxlength=100 :show-word-limit=true />
                    </FormItem>
                  </i-col>
                </Row>
              <Row :gutter="20" v-if="(formInline.standardtype=='3' || formInline.standardtype=='4') && (formInline.cccindex=='3' || formInline.cccindex=='4' || formInline.cccindex=='2' || formInline.cccindex=='5')">
                <i-col span="5" v-if="formInline.cccindex=='2'">
                  <FormItem prop="cellNum" label="3.5 蓄电池编码（前十位）:">
                    <i-input type="text" v-model="formInline.cellNum" :maxlength=30 />
                  </FormItem>
                </i-col>
                <i-col span="3">
                  <FormItem prop="chargerProducer" label="4.1 充电器生产企业:">
                    <i-input type="text" v-model="formInline.chargerProducer" :maxlength=50 />
                  </FormItem>
                </i-col>
                <i-col span="3">
                  <FormItem prop="chargerspecs" label="4.2 充电器型号:">
                    <i-input type="text" v-model="formInline.chargerspecs" :maxlength=20 />
                  </FormItem>
                </i-col>
                <i-col span="3" >
                  <FormItem label="4.3 充电方式:">
                    <i-input type="text" v-model="formInline.chargerMethod" :maxlength=15 :show-word-limit=true />
                  </FormItem>
                </i-col>
                <i-col span="3" v-if="formInline.standardtype=='4' && (formInline.cccindex=='2' || formInline.cccindex=='4')">
                  <FormItem label="4.4 充电器编码:">
                    <i-input type="text" v-model="formInline.chargerNum" :maxlength=15 :show-word-limit=true />
                  </FormItem>
                </i-col>
                  <i-col span="3">
                    <FormItem prop="frontwheel" label="5.1 前轮轮胎型号:"  v-show="formInline.cccindex=='3'">
                      <i-input type="text" v-model="formInline.frontwheel" :maxlength=15 :show-word-limit=true />
                    </FormItem>
                    <FormItem prop="frontwheel" label="5.1 前轮轮胎规格:"  v-show="formInline.cccindex=='4' || formInline.cccindex=='2' || formInline.cccindex=='5'">
                      <i-input type="text" v-model="formInline.frontwheel" :maxlength=15 :show-word-limit=true />
                    </FormItem>
                  </i-col>
                  <i-col span="3">
                    <FormItem prop="backwheel" label="5.2 后轮轮胎型号:" v-show="formInline.cccindex=='3'">
                      <i-input type="text" v-model="formInline.backwheel" :maxlength=15 :show-word-limit=true />
                    </FormItem>
                    <FormItem prop="backwheel" label="5.2 后轮轮胎规格:" v-show="formInline.cccindex=='4' || formInline.cccindex=='2' || formInline.cccindex=='5'">
                      <i-input type="text" v-model="formInline.backwheel" :maxlength=15 :show-word-limit=true />
                    </FormItem>
                  </i-col>
                  <i-col span="2" >
                    <FormItem prop="note" label="6 备注:" v-if="formInline.cccindex=='3' || formInline.cccindex=='2' || formInline.cccindex=='5'">
                      <i-input type="textarea" v-model="formInline.note" :maxlength=500 show-word-limit :show-word-limit=true style="width: 300px"s/>
                    </FormItem>
                    <FormItem prop="note" label="备注:" v-if="formInline.cccindex=='4'">
                      <i-input type="textarea" v-model="formInline.note" :maxlength=500 show-word-limit :show-word-limit=true style="width: 300px" />
                    </FormItem>
                  </i-col>
              </Row>
                <Row :gutter="5" v-else>
                  <i-col span="3">
                    <FormItem prop="frontwheel" label="4.1 前轮轮胎规格:">
                        <i-input type="text" v-model="formInline.frontwheel" :maxlength=15 :show-word-limit=true />
                    </FormItem>
                  </i-col>
                  <i-col span="3">
                    <FormItem prop="backwheel" label="4.2 后轮轮胎规格:">
                        <i-input type="text" v-model="formInline.backwheel" :maxlength=15 :show-word-limit=true />
                    </FormItem>
                  </i-col>

                  <i-col span="2">
                    <FormItem prop="note" label="5 备注:">
                        <i-input type="textarea" v-model="formInline.note" :maxlength=500 show-word-limit style="width: 300px"/>
                    </FormItem>
                  </i-col>
                  <i-col span="2">
                    <FormItem  label="选择水印">
                      <Select v-model="formInline.watermarkid"  @on-change="onwatermarkchange">
                          <Option value=0 key=0>请选择</Option>
                          <Option v-for="(item,index) in watermarklist" :value="item.id" :key="index+1">{{ watermarklist[index].watermarkname }}</Option>
                          <Option value=-1 key=-1 style="color:green">同步最新水印图数据</Option>
                      </Select>
                    </FormItem>
                  </i-col>
                  <i-col span="2">
                    <FormItem  label="选择检验章">
                      <Select v-model="formInline.sealaddrid"  @on-change="onsealchange">
                          <Option value=0 key=0>请选择</Option>
                          <Option v-for="(item,index) in seallist" :value="item.id" :key="index+1">{{ seallist[index].sealname }}</Option>
                          <Option value=-1 key=-1 style="color:green">同步最新印章图数据</Option>
                      </Select>
                    </FormItem>
                  </i-col>
                  <i-col span="6">
                    <FormItem prop="chargerbrand" label="5.1 充电器商标:">
                        <i-input type="text" v-model="formInline.chargerbrand" :maxlength=20 show-word-limit />
                    </FormItem>
                  </i-col>
                  <i-col span="6">
                    <FormItem  label="5.2 充电器型号规格:">
                        <i-input type="text" v-model="formInline.chargerspecs" :maxlength=20 show-word-limit />
                    </FormItem>
                  </i-col>
                </Row>
            </Card>
            <Card style="margin:10px 10px 10px 10px;">
                <p slot="title" align="left"><b>车辆图片信息</b></p>
                <Row :gutter="20">
                  <i-col span="12" >
                    <P style="color:red">友情提示:除上海外的全国其他区域请上传简图{{formInline.picchinaaddr}}</P>
                    <div class="demo-upload-list" v-if="formInline.picchinaaddr!=null">
                      <template >
                          <img ref="chinaimg" :src="'/api/files/get?imgpath='+this.formInline.picchinaaddr">
                        <div class="demo-upload-list-cover">
                          <Icon type="ios-search-outline" @click.native="handleView('china')"></Icon>
                        </div>
                        <div class="demo-upload-list-cover" style="position: sticky;">
                            <Upload
                                  ref="uploadchina"
                                  :show-upload-list="false"
                                  :data="{
                                    companyid: this.formInline.companyid,
                                    certificateno: this.formInline.certificateno,
                                    area:'china'
                                  }"
                                  :on-success="onuploadsuccesschina"
                                  :on-error="onuploaderrorchina"
                                  :format="['jpg','jpeg','png']"
                                  :max-size="200"
                                  :on-exceeded-size="onpicexceed"
                                  type="drag"
                                  action="/api/files/certificate/upload"
                                  style="width:58px;">
                              <div style="width: 58px;height:34px;margin-top: -20px">
                                <img src="@/assets/chongxin.png" style="height: 15px;">
                                </div>
                              </Upload>
<!--                          <Icon type="ios-eye-outline" @click.native="handleView('china')"></Icon>-->

                          <!-- <Icon type="ios-trash-outline" @click.native="doRemove('china')"></Icon>-->
                          </div>
                      </template>
                    </div>
                    <Upload
                        v-else
                      ref="uploadchina"
                      :show-upload-list="false"
                        :data="{
                        companyid: this.formInline.companyid,
                        certificateno: this.formInline.certificateno,
                        area:'china'
                      }"
                      :on-success="onuploadsuccesschina"
                      :on-error="onuploaderrorchina"
                      :format="['jpg','jpeg','png']"
                      :max-size="200"
                      :on-exceeded-size="onpicexceed"
                      type="drag"
                      action="/api/files/certificate/upload"
                      style="display: inline-block;width:58px;">
                      <div style="width: 58px;height:58px;line-height: 58px;">
<!--                          <Icon type="ios-camera" size="20"></Icon>-->
                        <img src="@/assets/shangchuan.jpg" style="width: 100%;height:100%;"/>
                      </div>
                    </Upload>
                    <P style="color:red">车辆图片(全国区域) 建议尺寸宽:400px 高:230px</P>
                  </i-col>
<!--                  <i-col span="12">-->
<!--                    <P style="color:red">友情提示:上海要求车头朝右，白色背景实物图片</P>-->
<!--                    <div class="demo-upload-list" >-->
<!--                      <template >-->
<!--                          <img ref="shimg" :src="'/api/files/get?imgpath='+formInline.picshanghaiaddr">-->
<!--                          <div class="demo-upload-list-cover">-->
<!--                              <Icon type="ios-eye-outline" @click.native="handleView('shanghai')"></Icon>-->
<!--                              <Icon type="ios-trash-outline" @click.native="doRemove('shanghai')"></Icon>-->
<!--                          </div>-->
<!--                      </template>-->
<!--                    </div>-->
<!--                    <Upload-->
<!--                      ref="uploadsh"-->
<!--                      :show-upload-list="false"-->
<!--                      :data="{-->
<!--                        companyid: this.formInline.companyid,-->
<!--                        certificateno: this.formInline.certificateno,-->
<!--                        area:'shanghai'-->
<!--                      }"-->
<!--                      v-bind:disabled="uploadDisable"-->
<!--                      :on-success="onuploadsuccesssh"-->
<!--                      :on-error="onuploaderrorsh"-->
<!--                      :format="['jpg','jpeg','png']"-->
<!--                      :max-size="200"-->
<!--                      :on-exceeded-size="onpicexceed"-->
<!--                      type="drag"-->
<!--                      action="/api/files/certificate/upload"-->
<!--                      style="display: inline-block;width:58px;">-->
<!--                      <div style="width: 58px;height:58px;line-height: 58px;">-->
<!--&lt;!&ndash;                          <Icon type="ios-camera" size="20"></Icon>&ndash;&gt;-->
<!--                        <img src="@/assets/shangchuan.jpg" style="width: 100%;height:100%;"/>-->
<!--                      </div>-->
<!--                    </Upload>-->
<!--                    <P style="color:red">车辆图片(上海区域)  建议尺寸宽:400px 高:230px</P>-->
<!--                  </i-col>-->
                </Row>
            </Card>
        <div style="height:1px;margin-top:15px"></div>
        <Modal
        v-model="showimg"
        title="查看图片"
        >
          <img :src="'/api/files/get?imgpath='+this.viewpicurl" style="width:400px;height:300px"/>
        </Modal>
    </Form>

    <div slot="footer">
      <Button type="error" size="large" ghost long @click="handlecancel" style="width:200px">取消</Button>
      <Button type="primary" size="large" long @click="handleSubmit('certificateEditForm')" style="width:200px;margin-left: 30px;">提交</Button>
    </div>
  </Modal>
</template>
<script>
import { truncate } from 'fs'
import {certificateService} from '@/api/certificate.js'
    export default {
        name:'CertificateEdit',
        props:['visibility','action'],
        data () {
          function trim(str) {
            if (str == null) {
                return "";
            }
            return str.replace(/^\s*(.*?)[\s\n]*$/g,'$1');
          }

          const validateinput = (rule, value, callback) => {
            if (value == '') {
              return callback(new Error('不能为空'))
            } else {
              return callback()
            }
          }

          const validatemodelids = (rule, value, callback) => {
            if (value.length <2 || value == '' || value == undefined) {
              return callback(new Error('不能为空'))
            } else {
              return callback()
            }
          }

          const validateccc = (rule, value, callback) => {
            console.log(this.formInline.standardtype+":"+value)
            if (this.formInline.standardtype ==1 && (value == '' ||value ==null)) {
              return callback(new Error('不能为空'))
            } else {
              return callback()
            }
          }

          const validatecccindex = (rule, value, callback) => {
            console.log(this.formInline.standardtype+":"+value)
            if (this.formInline.standardtype ==1 && (value <=0 ||value ==null)) {
              return callback(new Error('不能为空'))
            } else {
              return callback()
            }
          }

          const isDecimal = (rule, value, callback) => {
            const age= /^(([1-9]\d{0,4})|0)(\.\d{0,3})?$/
            if (!age.test(value)) {
              return callback(new Error('只能为整数或小数'))
            }else{
              return callback()
            }
          }
            return {
              abc:0,
              defaultList:[],
                formInline: {
                    id:null,
                    companyid:this._self.$root.$store.state.LogonInfo.companyid,
                    cnmodelno:'',
                    boxalias:'',
                    certificateno: '',
                    certificatealias: '',
                    modelids:[],
                    modelverid:null,
                    standardtype:'',
                    standardname:'',
                    manufacturer:null,
                    producer:null,
                    produceraddr:null,
                    cnbrand:null,
                    enbrand:null,
                    modelno:null,
                    drivetype:null,
                    bodysize:null,
                    wheeltrack:null,
                    totalweight:null,
                    maxspeed:null,
                    cardposition:null,
                    codeposition: null,
                    driverange: null,
                    powerconsumption: null,
                    cccno: null,
                    cccver: null,
                    cccdate: null,
                    cccindex:0,
                    productname:null,
                    motorproducer: null,
                    motormodel: null,
                    motortype: null,
                    rotationspeed: null,
                    outputpower:　null,
                    voltage:　null,
                    controllerproducer: null,
                    controllerno: null,
                    undervoltage: null,
                    overcurrent: null,
                    cellproducer:　null,
                    celltype: null,
                    cellvolume: null,
                    cellno: null,
                    frontwheel: null,
                    backwheel: null,
                    chargerbrand:null,
                    chargerspecs:null,
                    note: null,
                    watermarkid:0,
                    sealaddrid:0,
                    picchinaaddr:null,
                    picshanghaiaddr:null,
                    createuser:this._self.$root.$store.state.LogonInfo.companyid,
                    modifyuser:this._self.$root.$store.state.LogonInfo.companyid,
                },
                uploadDisable:true,
                standardTypeList:[
                  {label:'21版国标',value:1},
                  {label:'23版国标',value:3},
                  {label:'企标',value:2},
                  {label:'1号修改单',value:4}
                ],
              standardnameList:[
                {label:'GB17761-2018《电动自行车安全技术规范》',value:1},
                {label:'GB42295-2022《电动自行车电气安全要求》',value:2},
                {label:'GB42296-2022《电动自行车用充电器安全技术要求》',value:3},
              ],
                MasterModelDatalist:[],
                ccclist:this._self.$root.$store.state.CccList,
                watermarklist:this._self.$root.$store.state.WatermarkList,
                seallist:this._self.$root.$store.state.SealList,
                ruleInline: {
                  boxalias: [
                    {required: true, message: '不能为空!', trigger: 'blur'},
                  ],
                  certificateno: [
                    { required: true, message: '不能为空!', trigger: 'blur' },
                    { type: 'string', min: 8, max:20, message: '合格证号码需大于8位不超过20位', trigger: 'blur' }
                  ],
                  standardtype: [
                    {validator: validateinput, required:true, trigger: 'blur'},
                  ],
                  modelids: [
                    {validator: validatemodelids, required:true, trigger: 'blur'},
                  ],
                  standardname: [
                    { required: true, message: '不能为空!', trigger: 'blur' }
                  ],
                  manufacturer: [
                    { required: true, message: '不能为空!', trigger: 'blur' }
                  ],
                  producer: [
                    { required: true, message: '不能为空!', trigger: 'blur' }
                  ],
                  produceraddr: [
                    { required: true, message: '不能为空!', trigger: 'blur' }
                  ],
                  cnbrand: [
                    { required: false, message: '不能为空!', trigger: 'blur' }
                  ],
                  enbrand: [
                    { required: false, message: '不能为空!', trigger: 'blur' }
                  ],
                  modelno: [
                    { required: true, message: '不能为空!', trigger: 'blur' }
                  ],
                  drivetype: [
                    { required: true, message: '不能为空!', trigger: 'blur' }
                  ],
                  bodysize: [
                    { required: true, message: '不能为空!', trigger: 'blur' }
                  ],
                  wheeltrack: [
                    { required: true, message: '不能为空!', trigger: 'blur' }
                  ],
                  totalweight: [
                    {validator: validateinput, required:true, trigger: 'blur'},
                    { validator: isDecimal, trigger: 'blur' }
                    //{ required: true, message: '不能为空!', trigger: 'blur' },
                    //{ message: '只能是小数或整数', trigger:'blur', pattern:/^(([1-9]\d{0,3})|0)(\.\d{0,2})?$/}
                  ],
                  maxspeed: [
                    {validator: validateinput, required:true, trigger: 'blur'},
                    { validator: isDecimal, trigger: 'blur' }
                  ],
                  cardposition: [
                    { required: true, message: '不能为空!', trigger: 'blur' }
                  ],
                  codeposition: [
                    { required: true, message: '不能为空!', trigger: 'blur' }
                  ],
                  driverange: [
                    {validator: validateinput, required:true, trigger: 'blur'},
                    { validator: isDecimal, trigger: 'blur' }
                  ],
                  //有的电耗值为 <=1.2
                  powerconsumption: [
                    { required: true, message: '不能为空!', trigger: 'blur' }
                  ],
                  cccno: [
                    { validator:validateccc, trigger: 'blur' }
                  ],
                  cccver: [
                    { validator:validateccc, trigger: 'blur' }
                  ],
                  cccdate: [
                    { validator:validateccc, trigger: 'blur' }
                  ],
                  cccindex: [
                    { validator:validatecccindex, trigger: 'blur' }
                  ],
                  motorproducer: [
                    { required: true, message: '不能为空!', trigger: 'blur' }
                  ],
                  motormodel: [
                    { required: true, message: '不能为空!', trigger: 'blur' }
                  ],
                  motortype: [
                    { required: true, message: '不能为空!', trigger: 'blur' }
                  ],
                  rotationspeed: [
                    {validator: validateinput, required:true, trigger: 'blur'},
                  ],
                  outputpower: [
                    {validator: validateinput, required:true, trigger: 'blur'},
                  ],
                  voltage: [
                    {validator: validateinput, required:true, trigger: 'blur'},
                    { validator: isDecimal, trigger: 'blur' }
                  ],
                  controllerproducer: [
                    { required: true, message: '不能为空!', trigger: 'blur' }
                  ],
                  controllerno: [
                    { required: true, message: '不能为空!', trigger: 'blur' }
                  ],
                  undervoltage: [
                    { required: true, message: '不能为空!', trigger: 'blur' }
                  ],
                  overcurrent: [
                    { required: true, message: '不能为空!', trigger: 'blur' }
                  ],
                  cellproducer: [
                    { required: true, message: '不能为空!', trigger: 'blur' }
                  ],
                  celltype: [
                    { required: true, message: '不能为空!', trigger: 'blur' }
                  ],
                  cellvolume: [
                    { required: true, message: '不能为空!', trigger: 'blur' }
                  ],
                  cellno: [
                    { required: true, message: '不能为空!', trigger: 'blur' }
                  ],
                  chargerProducer: [
                    { required: true, message: '不能为空!', trigger: 'blur' }
                  ],
                  chargerMethod: [
                    { required: true, message: '不能为空!', trigger: 'blur' }
                  ],
                  chargermodel: [
                    { required: true, message: '不能为空!', trigger: 'blur' }
                  ],
                  chargingmethod: [
                    { required: true, message: '不能为空!', trigger: 'blur' }
                  ],
                  frontwheel: [
                    { required: true, message: '不能为空!', trigger: 'blur' }
                  ],
                  backwheel: [
                    { required: true, message: '不能为空!', trigger: 'blur' }
                  ],
                  note: [
                    { required: false, message: '请输入合格证别名', trigger: 'blur' }
                  ],
                  chargerspecs: [
                    { required: true, message: '不能为空', trigger: 'blur' }
                  ],
                },
            viewpicurl:null,
            showimg:false
            }
        },
        watch:{
          'visibility': function(newv,oldv){
            console.log("合格证编辑显示",newv)
            if(newv){
              this.MasterModelDatalist = this._self.$root.$store.state.MasterModelChooseList

              if(this.action == 'add'){
                //add

              }
              else if(this.action == 'modify'){
                //modify
                this.formInline = this._self.$root.$store.state.CertificateEditData
                this.formInline.modelids = this.findmodelids(this.formInline.modelverid,this.MasterModelDatalist)
                this.formInline.standardname2 = this.formInline.standardname2.split(",")
                //console.log(localStorage.getItem('certificateEditData'))
              }
              else if(this.action == 'copy'){
                //copy
                this.formInline = this._self.$root.$store.state.CertificateEditData
                this.formInline.certificateno=''
                this.formInline.certificatealias=''
                this.formInline.cnmodelno=''
                this.formInline.modelverid = null
                this.formInline.cnbrand = ''
                this.formInline.enbrand = ''
                this.formInline.picchinaaddr = null
                this.formInline.picshanghaiaddr = null
                this.uploadDisable = false
                this.formInline.modelids = []
              }

              console.log("合格证编辑页面数据",this.formInline)
            }
          },
          'formInline.certificateno': function (val) {
               /* ... */
               if(val==''){
                 this.uploadDisable = true
               }
               else{
                 this.uploadDisable = false
               }
            },
          'formInline.picchinaaddr':  {
              handler:function(val){
                this.$refs['chinaimg'].src ="/api/files/get?imgpath="+val
              }
            },
          // 'formInline.picshanghaiaddr':function (val, oldVal) {
          //     this.$refs['shimg'].src ="/api/files/get?imgpath="+val
          //   },
        },
        mounted(){
          //console.log(this.action)
          this.MasterModelDatalist = this._self.$root.$store.state.MasterModelChooseList

          console.log("认证机构列表",this.ccclist)
          if(this.ccclist ==null){
            this.refreshCccList()
          }

          if(this.watermarklist ==null){
            this.refreshPicList('watermark')
          }

          if(this.seallist ==null){
            this.refreshPicList('seal')
          }

          if(this.action == 'add'){
            //add

          }
          else if(this.action == 'modify'){
            //modify
            this.formInline = this._self.$root.$store.state.CertificateEditData
            this.formInline.modelids = this.findmodelids(this.formInline.modelverid,this.MasterModelDatalist)

            console.log("222222222",this.formInline)
            //console.log(localStorage.getItem('certificateEditData'))
          }
          else if(this.action == 'copy'){
            //copy
            this.formInline = this._self.$root.$store.state.CertificateEditData
            this.formInline.certificateno=''
            this.formInline.certificatealias=''
            this.formInline.cnmodelno=''
            this.formInline.modelverid = null
            this.formInline.cnbrand = ''
            this.formInline.enbrand = ''
            this.formInline.picchinaaddr = null
            this.formInline.picshanghaiaddr = null
            this.uploadDisable = false

          }
        },
        methods: {
          grab(){
            certificateService.grab({cccindex:this.formInline.cccindex,vehicleNo:this.formInline.vehicleNo,companyid:this.formInline.companyid})
                .then(({ data }) => {
                  console.log("aaa",data)
                  if(data.data.standardname2!=null){
                    data.data.standardname2 = data.data.standardname2.toString().split(",")
                  }
                  // return
                  var forms = JSON.parse(JSON.stringify(this.formInline))
                  this.formInline = data.data
                  this.formInline.boxalias = forms.boxalias
                  this.formInline.standardtype = forms.standardtype
                  this.formInline.standardname = forms.standardname
                  // this.formInline.standardname2 = "GB42295-2022《电动自行车电气安全要求》,GB17761-2018《电动自行车安全技术规范》"
                  this.formInline.cccindex = forms.cccindex
                   // if(this.formInline.standardtype==3  && (this.formInline.cccindex== 3 || this.formInline.cccindex==4 || this.formInline.cccindex==2)){
                   //   this.formInline.chargerProducer = forms.chargerProducer
                   //   this.formInline.chargerspecs = forms.chargerspecs
                   //   this.formInline.chargerMethod = forms.chargerMethod
                   // }
                  // this.formInline.chargerNum = forms.chargerNum
                  this.formInline.companyid = forms.companyid
                  this.formInline.createuser = forms.createuser
                  this.formInline.deleted = false
                  this.formInline.modifyuser = forms.modifyuser
                  this.formInline.id = forms.id
                  this.formInline.createtime = forms.createtime
                  this.formInline.modifytime = forms.modifytime
                  // this.formInline.note = forms.note
                  // this.abc+=1

                  console.log("this.formInline",this.formInline)
                })
          },
            handlecancel(){
                this.$emit('onCancel','')
            },
            handleSubmit(name) {
              console.log(this.ccclist)
              console.log(this._self.$root.$store.state.CccList)
              console.log(this.formInline.picchinaaddr)
              this.$refs[name].validate(async (valid) => {
                  if (valid) {
                    if(this.formInline.picchinaaddr==null || this.formInline.picchinaaddr == 'null' || this.formInline.picchinaaddr == ''){
                        this.$Message.error({content:'车辆简图必须上传!',duration:3})
                        return
                    }
                    var url=''
                    var postdata=this.extend({},this.formInline)
                    if(this.formInline.cccdate ==null){
                      delete postdata.cccdate
                    }
                    if(this.formInline.sealaddrid ==null){
                      postdata.sealaddrid =0
                    }
                    if(this.formInline.watermarkid ==null){
                      postdata.watermarkid =0
                    }

                    let result=null
                    if(this.action == 'add' || this.action =='copy'){
                      delete postdata.id
                      delete postdata.createtime
                      delete postdata.modifytime
                      console.info("12312312",postdata)
                      certificateService.create(postdata)
                      .then(({ data }) => {
                        console.log(data)
                        if (data.resultCode == 0) {
                          this.$Message.success({content:data.msg, duration:3, closable:true})
                          this.$router.go(-1)
                        }
                        else{
                          this.$Message.error({content:data.msg, duration:3})
                        }
                      })
                    }
                    else if(this.action =='modify'){
                      console.log("1111111111111111111",postdata)

                      certificateService.update(postdata)
                      .then(({ data }) => {
                        if (data.resultCode == 0) {
                          this.$Message.success({content:data.msg, duration:3, closable:true})
                          this.$emit('onSaveOver','')
                        }
                        else{
                          this.$Message.error({content:data.msg, duration:3})
                        }
                      })
                    }
                  } else {
                      this.$Message.error('填写不正确,请检查错误项重新填写!');
                  }
              })
            },
            oncccchange(v){
              if(v==-1){
                this.refreshCccList()
              }
              if(this.formInline.standardtype == 1 || ( this.formInline.standardtype == 2 && v!='3')){
                this.formInline.standardname = 'GB 17761-2018《电动自行车安全技术规范》'
              }else if(this.formInline.standardtype == 4){
                if(v==4 || v==2){
                  this.formInline.standardname = "GB 17761-2018《电动自行车安全技术规范》；GB 42295-2022《电动自行车电气安全要求》及第 1 号修改单；GB 42296-2022《电动自行车用充电器安全技术要求》及第 1 号修改单"
                }else{
                  this.formInline.standardname = ''
                }
              }
            },
            onwatermarkchange(v){
              if(v==-1){
                this.refreshPicList('watermark')
                this.formInline.watermarkid = 0
              }
            },
            onsealchange(v){
              if(v==-1){
                this.refreshPicList('seal')
                this.formInline.sealaddrid = 0
              }
            },
            onStandardChange(v) {
              console.log(v)
              if(v == 1 || ( v == 2 && this.formInline.cccindex!='3')){
                this.formInline.standardname = 'GB 17761-2018《电动自行车安全技术规范》'
              }else if(v==4 ){
                if(this.formInline.cccindex==4 || this.formInline.cccindex==2){
                  this.formInline.standardname = "GB 17761-2018《电动自行车安全技术规范》；GB 42295-2022《电动自行车电气安全要求》及第 1 号修改单；GB 42296-2022《电动自行车用充电器安全技术要求》及第 1 号修改单"
                }
              }
              else{
                this.formInline.standardname = ''
              }

            },
            findmodelids(modelverid,fromarray){
              console.log("findmodelids",fromarray)
              if(fromarray == undefined){
                return []
              }

              for(let i=0;i<fromarray.length;i++){
                var parentnode = fromarray[i]
                for(let j=0;j<parentnode.children.length;j++){
                  if(parentnode.children[j].value == (modelverid+'')){
                    console.log("childvalue判断成功")
                    return [parentnode.value, parentnode.children[j].value]
                  }
                }
              }
              return []
            },
            onmodelverchange(e,selectedData){
              console.log(e)
              console.log(selectedData)
              if(selectedData.length >1){
                this.formInline.cnmodelno = selectedData[0].value
                this.formInline.modelverid = selectedData[1].value
                this.formInline.certificatealias = selectedData[1].label
              }
            },
            onuploadsuccesschina(response, file){
              this.formInline.picchinaaddr = response.data.filepath
              this.$refs['chinaimg'].src ="/api/files/get?imgpath="+this.formInline.picchinaaddr
              this.$Message.success('上传成功')
            },
            onuploaderrorchina(response, file){
              console.log(response)
              console.log(file)
            },
            onuploadsuccesssh(response, file){
              console.log(response)
              console.log(file)
              this.formInline.picshanghaiaddr = response.data.filepath
              this.$refs['shimg'].src ="/api/files/get?imgpath="+this.formInline.picshanghaiaddr
              this.$Message.success('上传成功')
            },
            onuploaderrorsh(response, file){
              console.log(response)
              console.log(file)
            },
            onpicexceed(f){
              this.$Message.error({content:'上传失败，文件超出200k！',duration:3})
            },
            handleView (area) {
              console.log('handleView')
              console.log(area)
              console.log(this.formInline.picchinaaddr)
              if(area =='shanghai'){
                this.viewpicurl = this.formInline.picshanghaiaddr
              }
              else{
                this.viewpicurl = this.formInline.picchinaaddr
              }
              this.showimg = true
            },
            doRemove(area){
              this.$axios({
                method:'post',
                url:'/api/files/certificate/upload',
                data:{ companyid: this.formInline.companyid,
                  certificateno: this.formInline.certificateno,
                  area:'china'},
                headers:{'Content-Type': 'application/x-www-form-urlencoded'}
                // transformRequest: function(obj) {
                //   var str = [];
                //   for(var p in obj){
                //     str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
                //   }
                //   return str.join("&");
                // }
              }).then(function(res){
                console.log(res)
                //onuploadsuccesschina
              })

              // this.$Modal.confirm({
              //   content:'确定要删除图片吗？',
              //   onOk:()=>{
              //     this.handleRemove(area)
              //   }
              // })
            },
            handleRemove (area) {
              var picpath
              if(area == 'shanghai'){
                picpath = this.formInline.picshanghaiaddr
              }
              else if(area =='china'){
                picpath = this.formInline.picchinaaddr
              }
              // certificateService.update({id:})
              //     .then(({ data }) => {
              //       console.log(data)
              //       // if (data.resultCode == 0) {
              //       //   this.$Message.success({content:data.msg, duration:3, closable:true})
              //       //   this.$router.go(-1)
              //       // }
              //       // else{
              //       //   this.$Message.error({content:data.msg, duration:3})
              //       // }
              //     })
              // this.$Message.success({content:res.data.msg, duration:3, closable:true})
              //
              // if(area == 'shanghai'){
              //   this.formInline.picshanghaiaddr =null
              //   this.$refs['shimg'].src =null
              // }
              // else{
              //   this.formInline.picchinaaddr =null
              //   this.$refs['chinaimg'].src =null
              // }

              /* this.$axios({
                method:'post',
                url:'/api/files/delete',
                data:{path:picpath},
                headers:{'Content-Type': 'application/x-www-form-urlencoded'},
                transformRequest: function(obj) {
                    var str = [];
                    for(var p in obj){
                        str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
                    }
                    return str.join("&");
                }
              }).then(function(res){
                if(res.data.resultCode ==0){
                  this.$Message.success({content:res.data.msg, duration:3, closable:true})

                  if(area == 'shanghai'){
                    this.formInline.picshanghaiaddr =null
                    this.$refs['shimg'].src =null
                  }
                  else{
                    this.formInline.picchinaaddr =null
                    this.$refs['chinaimg'].src =null
                  }
                }
                else{
                  this.$Message.error({content:res.data.msg, duration:3})
                }
              }.bind(this)); */
            },
            refreshCccList(){
                axios.post("/api/ccc/query")
                .then(function(res){
                    if(res.data.resultCode ==0){
                        var rspdata = res.data.data
                        this.ccclist = JSON.parse(JSON.stringify(rspdata))
                        this.$store.commit('changeCccList',this.ccclist)
                        this.$Message.success({content:res.data.msg, duration:3})
                        if(this.cccindex<0){
                          this.cccindex = 0
                        }
                    }
                    else{
                        this.$Message.error({content:res.data.data.msg, duration:3})
                    }
                }.bind(this));
            },
            refreshPicList(name){
              axios.post("/api/"+name+"/query",{companyid:this.formInline.companyid})
              .then(function(res){
                    if(res.data.resultCode ==0){
                        var rspdata = res.data.data
                        if(name =='watermark'){
                          this.watermarklist =rspdata
                          this.$store.commit('changeWatermarkList',this.watermarklist)
                          if(this.formInline.watermarkid<0){
                            this.watermarkid = 0
                          }
                        }
                        else if(name =='seal'){
                          this.seallist =rspdata
                          this.$store.commit('changeSealList',this.seallist)
                          if(this.formInline.sealaddrid<0){
                            this.sealaddrid = 0
                          }
                        }
                        this.$Message.success({content:res.data.msg, duration:3})

                    }
                    else{
                        this.$Message.error({content:res.data.data.msg, duration:3})
                    }
                }.bind(this));
            },

        }
    }
</script>

<style>
    .demo-upload-list{
        display: inline-block;
        width: 60px;
        height: 60px;
        text-align: center;
        line-height: 60px;
        border: 1px solid transparent;
        border-radius: 4px;
        overflow: hidden;
        background: #fff;
        position: relative;
        box-shadow: 0 1px 1px rgba(0,0,0,.2);
        margin-right: 4px;
    }
    .demo-upload-list img{
        width: 100%;
        height: 100%;
    }
    .demo-upload-list-cover{
        display: none;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background: rgba(0,0,0,.6);
    }
    .demo-upload-list:hover .demo-upload-list-cover{
        display: block;
    }
    .demo-upload-list-cover i{
        color: #fff;
        font-size: 20px;
        cursor: pointer;
        margin: 0 2px;
    }
</style>
